import { Navigate, Route } from "react-router-dom";
import AuthLayout from "../components/layout/AuthLayout/";
import Login from "../components/pages/AuthPages/Login";
import HomePage from "../components/pages/HomePage";
import DashboardLayout from "../components/layout/DashboardLayout";
import UserManagement from "../components/pages/User-management";
// import Reports from "../components/pages/Reports";
// import PlansAndPayments from "../components/pages/PlansAndPayments";

import Verification from "../components/pages/AuthPages/Verification";
import ForgotPassword from "../components/pages/AuthPages/ForgotPassword";
import ResetPassword from "../components/pages/AuthPages/ResetPassword";
import Videos from "../components/pages/Videos";
import AddVideos from "../components/pages/Videos/AddVideos";
import VideoPlr from "../components/pages/videoplr";
import VideoView from "../components/pages/Videos/View";
import VideoPlayer from "../components/pages/videoplr/video";
import PreviewVideo from "../components/pages/videoplr/preview/preview";
import ProtectedRoute from "./ProtectedRoute";
import VideoShare from "../components/pages/VideoShare";
import Profile from "../components/pages/Profile";
import Test from "../components/pages/Test";
import None from "../components/pages/None";
import GuestGuard from "./GuestGaurd";

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" replace />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword type="reset" />,
      },
      {
        path: "create-password",
        element: <ResetPassword type="create" />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Route path="/" element={<HomePage />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "user-management",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<UserManagement />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "none",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<None />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "videos",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<Videos />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "add-video",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<AddVideos />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "video-plr",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<VideoPlr />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "videos/video-preview/:id?",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<PreviewVideo />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "videos/videoplayer/:id?",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<VideoPlayer />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "videos/video-view/:id?",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<VideoView />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Route path="/" element={<Profile />} />
          </ProtectedRoute>
        ),
      },
      {
        path: "test",

        element: (
          <ProtectedRoute>
            <Route path="/" element={<Test />} />
          </ProtectedRoute>
        ),
      },
      // {
      //   path : 'plans',
      //   element : <PlansAndPayments/>
      // },
      // {
      //   path : 'reports',
      //   element : <Reports/>
      // }
    ],
  },
  {
    path: "/video-vogue/:id?",
    element: <VideoShare />,
  },
];

export default routes;

// export const dashboardRoutes = (
//   <Suspense>
//     <Routes>
//       <Route path="/dashboard" element={<HomePage />} />
//     </Routes>
//   </Suspense>
// );
