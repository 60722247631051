import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../services";
import Cart from "../../icons/cart";

const VideoShare = () => {
  const videoRef = useRef(null);
  const [cookies] = useCookies(["t", "bskey"]);
  const { id } = useParams();
  const [videoData, setVideoData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoStatus, setVideoStatus] = useState(false);

  const getVideoDataById = async () => {
    try {
      const response = await getData({
        endpoint: "Video/getVideoPreview",
        params: {
          video_id: id,
        },
        token: cookies.t,
        secretkey: cookies.bskey,
      });
      setVideoData(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVideoDataById();
  }, []);

  // Function to handle time update in the video
  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  // Function to check if the current time is within the range of any item
  const checkTimeInRange = ({ time, item }) => {
    return time >= item.start_time && time <= item.end_time;
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    // Add event listeners for time update
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      // Cleanup event listeners
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const handleItemHover = () => {
    if (videoRef?.current?.paused) setVideoStatus(true);
    else setVideoStatus(false);
    videoRef.current.pause();
  };

  const handleItemHoverRemove = () => {
    if (!videoStatus) videoRef?.current?.pause();
    else videoRef?.current?.play();
  };

  return (
    <div className="video-add-conatiner-main">
      <div style={{ position: "relative" }}>
        <video
          className="video-container"
          ref={videoRef}
          src={videoData?.video}
          controls
        ></video>


        {videoData?.product_description && (
          <>
            {videoData?.product_description?.map(
              (item) =>
                checkTimeInRange({
                  time: currentTime,
                  item: item,
                }) && (
                  <div
                    className="item"
                    style={{
                      position: "absolute",
                      top: `${item.y_coordinate}%`,
                      left: `${item.x_coordinate}%`,
                      width: "20px",
                      height: "20px",
                    }}
                    onMouseEnter={handleItemHover}
                    onMouseLeave={handleItemHoverRemove}
                  >
                    <div className="item-dot-container"></div>
                    <div
                      className="item-content-display"
                      id="hover-container"
                      style={{ left: `calc(${item.x_coordinate}%)` }}
                    >
                      <img src={item?.photo} />
                      <div className="item-content-display-hover">
                        <h3>{item?.name}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
        )}
        {videoData?.product_card && (
          <>
            {videoData?.product_card?.map(
              (item) =>
                checkTimeInRange({
                  time: currentTime,
                  item: item,
                }) && (
                  <div
                    className="item shopping-item-icon-container"
                    style={{
                      position: "absolute",
                      top: `${item.y_coordinate}%`,
                      left: `${item.x_coordinate}%`,
                      width: "20px",
                      height: "20px",
                    }}
                    onMouseEnter={handleItemHover}
                    onMouseLeave={handleItemHoverRemove}
                  >
                    <div className="item-shopping-icon ">
                      <Cart />
                    </div>
                    <div
                      className="item-content-display"
                      id="hover-container"
                      style={{
                        left: `calc(${item.x_coordinate}% + 9px)`,
                      }}
                    >
                      <img src={item?.photo} />
                      <div className="item-content-display-hover">
                        <h3>{item?.name}</h3>
                        <h4>${item?.price}</h4>
                        <p>{item?.description}</p>
                        <button
                          className="btn cart-btn-continer"
                          onClick={() => item?.link}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
        )}
        {videoData?.product_highlight && (
          <>
            {videoData?.product_highlight?.map(
              (item) =>
                checkTimeInRange({
                  time: currentTime,
                  item: item,
                }) && (
                  <div
                    className="item-highlight"
                    style={{
                      position: "absolute",
                      top: `${item.y_coordinate}%`,
                      left: `${item.x_coordinate}%`,
                    }}
                    onMouseEnter={handleItemHover}
                    onMouseLeave={handleItemHoverRemove}
                  >
                    <div className="item-highlight-container">{item?.name}</div>
                  </div>
                )
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default VideoShare;
