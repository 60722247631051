// import React, { useState, useRef, useEffect } from 'react';
// import './None.scss'; // Import your SCSS file
// import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress } from 'react-icons/fa';

// function None() {
//     const [isPlaying, setIsPlaying] = useState(false);
//     const [mute, setMute] = useState(false);
//     const [volume, setVolume] = useState(1);
//     const [fullscreen, setFullscreen] = useState(false);
//     const [currentTimePlayer, setCurrentTimePlayer] = useState(0);
//     const [duration, setDuration] = useState(0);
//     const videoRef = useRef(null);
//     const seekBarRef = useRef(null);
//     const volumeBarRef = useRef(null);

//     // Play/pause toggle function
//     const togglePlayPause = () => {
//         if (videoRef.current.paused) {
//             videoRef.current.play();
//             setIsPlaying(true);
//         } else {
//             videoRef.current.pause();
//             setIsPlaying(false);
//         }
//     };

//     // Mute/unmute toggle function
//     const toggleMute = () => {
//         videoRef.current.muted = !mute;
//         setMute(!mute);
//     };

//     // Volume change function
//     const handleVolumeChange = (event) => {
//         const volume = event.target.value;
//         videoRef.current.volume = volume;
//         setVolume(volume);
//         setMute(volume === '0');
//     };

//     // Fullscreen toggle function
//     const toggleFullscreen = () => {
//         if (!document.fullscreenElement) {
//             videoRef.current.requestFullscreen();
//             setFullscreen(true);
//         } else {
//             document.exitFullscreen();
//             setFullscreen(false);
//         }
//     };

//     // Update seek bar and volume bar as video plays
//     const updateSeekBarAndVolume = () => {
//         const currentTime = videoRef.current.currentTime;
//         const duration = videoRef.current.duration;
//         setCurrentTimePlayer(currentTime);
//         setDuration(duration);
//         seekBarRef.current.value = (currentTime / duration) * 100;
//         volumeBarRef.current.value = volume;
//     };

//     const formatTime = (time) => {
//         const minutes = Math.floor(time / 60);
//         const seconds = Math.floor(time % 60);
//         return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//     };

//     // useEffect to remove default controls and handle click event
//     useEffect(() => {
//         const vids = document.querySelectorAll('video');
//         vids.forEach((video) => {
//             video.controls = false;
//             video.addEventListener('click', () => {
//                 if (video.paused) {
//                     video.play();
//                 } else {
//                     video.pause();
//                 }
//             });
//             video.removeAttribute('controls'); // Remove controls after adding event listener
//         });
//     }, []);

//     return (
//         <div className="video-container">
//             <video
//                 ref={videoRef}
//                 className="video"
//                 controls
//                 controlsList="nodownload"
//                 onClick={togglePlayPause}
//                 onTimeUpdate={updateSeekBarAndVolume}
//                 onEnded={() => setIsPlaying(false)}
//                 style={{ backgroundColor: "transparent" }}
//             >
//                 <source src="https://video-vogue-bucket.s3.amazonaws.com/video vogue/videos/virat2/b2e5735b-8a12-4e2a-88f7-3cbe4c589005_Virat Kohli & Anushka Sharma toothsi-(1080p).mp4" type="video/mp4" />
//                 Your browser does not support the video tag.
//             </video>
//             <div className="custom-controls">
//                 <button onClick={togglePlayPause}>{isPlaying ? <FaPause /> : <FaPlay />}</button>
//                 <span className="video-timer">{formatTime(currentTimePlayer)}/{formatTime(duration)}</span>
//                 <div className="seek-volume-container">
//                     <input
//                         ref={seekBarRef}
//                         type="range"
//                         className="seek-bar"
//                         min="0"
//                         max="100"
//                         step="1"
//                         defaultValue="0"
//                         onChange={(e) => {
//                             const seekTime = (e.target.value / 100) * videoRef.current.duration;
//                             videoRef.current.currentTime = seekTime;
//                         }}
//                     />
//                     <button onClick={toggleMute}>{mute ? <FaVolumeMute /> : <FaVolumeUp />}</button>
//                     <input
//                         ref={volumeBarRef}
//                         type="range"
//                         className="volume-bar"
//                         min="0"
//                         max="1"
//                         step="0.01"
//                         value={volume}
//                         onChange={handleVolumeChange}
//                     />
//                 </div>
//                 <button onClick={toggleFullscreen}>{fullscreen ? <FaCompress /> : <FaExpand />}</button>
//             </div>
//         </div>
//     );
// }

// export default None;
import "./None.scss";
const None = () => {
  return (
    <>
      <iframe
        width="645"
        height="400px"
        src="https://public-dev.videovogue.ai/video/414f7c55-8e51-40f9-bf67-db6ff2d405a6/embed"
        title="video-vogue"
      />
    </>
  );
};
export default None;
