import React, { useEffect, useState } from "react";
import "./styles.scss";
// import video from "./newMyntra.mp4";
import video from "./prod.mp4";
import VideoPlr from "../../videoplr";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getData, patchData } from "../../../../services";
import PageCard from "../../../atoms/PageCard";
import EditIcon from "../../../icons/editIcon";
import Modal from "../../../atoms/Modals/Modal";
import LogoutModal from "../../../atoms/Modals/Logout";
import InputLayout from "../../../atoms/InputLayout";
import { date } from "yup";
import PageHeader from "../../../atoms/PageHeader";

const VideoView = () => {
  // eslint-disable-next-line
  // const player = new Plyr("video", { captions: { active: true } });
  const [listedData, setListedData] = useState([]);
  const [unListedData, setUnlistedData] = useState([]);
  const [cookies] = useCookies(["t"]);
  const [videoData, setVideoData] = useState(false);
  const [editingName, setEditingName] = useState("");
  const [itemByClick, setItemByClick] = useState(false);
  const [firstItem, setFirstItem] = useState([]);
  const { id } = useParams();

  const getVideoDetail = async (id, token) => {
    console.log("id :", id, token);
    try {
      const response = await getData({
        endpoint: "Video/getAllVideoDetails",
        params: { id: id },
        token: token,
      });
      setVideoData(response[0]);
      return response[0];
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const data = await getVideoDetail(id, cookies.t);
        setVideoData(data);
        console.log(videoData);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    fetchVideoDetails();
  }, [id, cookies.t]);

  useEffect(() => {
    // Extract unique descriptions from the JSON data
    const descriptions = [];
    const list_descriptions = [];

    videoData?.filtered_data?.map((item) => {
      if (!list_descriptions.includes(item?.entity_description)) {
        list_descriptions.push(item?.entity_description);
        descriptions.push(item);
      }
    });

    // Update the state with the filtered descriptions
    setListedData(descriptions);

    const unListeddescriptions = [];
    const unlist_descriptions = [];
    videoData?.unfiltered_data?.map((item) => {
      if (!unlist_descriptions.includes(item?.entity_description)) {
        unlist_descriptions.push(item?.entity_description);
        unListeddescriptions.push(item);
      }
    });

    // Update the state with the filtered descriptions
    setUnlistedData(unListeddescriptions);
  }, [videoData]);

  const handleVideoAtTime = (startTime) => {
    console.log("startTime :", startTime);
    // eslint-disable-next-line
    const player = new Plyr("video", { captions: { active: true } });
    if (player) {
      player.pause();
      player.currentTime = startTime;
      player.pause();
    }
  };
  const handleVideoItem = (e) => {
    const text = e.target?.textContent;
    const filteredData = videoData.filtered_data.filter((item) => {
      return item.entity_description.trim() === text.trim();
    });
    console.log("listedfiltereddata", filteredData);

    if (filteredData.length > 0) {
      const firstItem = filteredData[0];

      const time = firstItem?.start_time;
      setFirstItem([firstItem]);
      setItemByClick(true);
      handleVideoAtTime(time);
      // handleunBoundingBoxes(firstItem);
    }
  };

  const handleunBoundingBoxes = (updatedFirstItem) => {
    const imageContainer = document.getElementById("image-container");
    const videoContainer = document.getElementById("varun");

    // Clear existing bounding boxes
    const clearBoundingBoxes = () => {
      const boundingBoxes = document.querySelectorAll(".bounding-box");
      const boundingBoxesDescription = document.querySelectorAll(
        ".bounding-box-description"
      );
      boundingBoxes.forEach((box) => box.remove());
      boundingBoxesDescription.forEach((box) => box.remove());
      imageContainer.innerHTML = "";
    };

    clearBoundingBoxes(); // Clear existing bounding boxes

    if (updatedFirstItem) {
      const { description } = updatedFirstItem?.entity_description;
      const { left, top, right, bottom } = updatedFirstItem.coordinates;
      console.log(
        "des :",
        updatedFirstItem,
        updatedFirstItem?.entity_description,
        description
      );
      // Show the bounding box
      const box = document.createElement("div");
      box.className = "bounding-box";
      box.style.left = `${left * 100}%`;
      box.style.top = `${top * 100}%`;
      box.style.width = `${(right - left) * 100}%`;
      box.style.height = `${(bottom - top) * 100}%`;
      box.style.position = "absolute";
      box.style.border = "2px solid rgb(43, 255, 0)";
      box.style.boxSizing = "border-box";
      box.style.zIndex = "1";
      box.style.color = "rgb(43, 255, 0)";

      const contentBox = document.createElement("div");
      contentBox.className = "bounding-box-description";
      contentBox.innerHTML = `<p>${updatedFirstItem?.entity_description}</p>`;
      contentBox.style.top = `calc(${top * 100}% - 24px)`;
      contentBox.style.left = `${left * 100}%`;
      contentBox.style.position = "absolute";
      contentBox.style.boxSizing = "border-box";
      contentBox.style.zIndex = "1";
      contentBox.style.color = "yellow";
      box.setAttribute(
        "data-description",
        updatedFirstItem?.entity_description
      );

      if (videoContainer) {
        videoContainer.appendChild(contentBox);
        videoContainer.appendChild(box);
      } else {
        console.log("vjs-text-track-display element not found");
      }
    }
  };

  //// Unlisted Filtered Data
  const handleUnfilteredData = (e) => {
    const text = e.target?.textContent;
    const filteredData = videoData.unfiltered_data.filter((item) => {
      return item.entity_description === text;
    });
    console.log("Unlistedfiltereddata", filteredData);
    if (filteredData.length > 0) {
      const firstItem = filteredData[0];

      console.log("firstItem", firstItem);
      setFirstItem([firstItem]);
      const time = firstItem?.start_time;
      console.log("time", time);
      setItemByClick(true);
      handleVideoAtTime(time);
      // handleunBoundingBoxes(firstItem);
      // eslint-disable-next-line
      // const player = new Plyr("video", { captions: { active: true } });
      // player.once("timeupdate", () => {
      //   handleunBoundingBoxes(updatedFirstItem);
      // });
    }
  };
  console.log(videoData?.video_link);
  useEffect(() => {
    const videoHeight = document.getElementsByClassName("plyr__poster")[0];
    console.log("videoHeight  :", videoHeight);
  }, []);
  const [unListesdModal, setUnlistedModal] = useState({
    isActive: false,
    data: "",
  });
  const handleUnfilteredEdit = (data) => {
    setUnlistedModal({
      isActive: true,
      data: data,
    });
  };
  const handleMoveListed = async (data) => {
    try {
      const response = await patchData({
        endpoint: "Video/editUnfilterData",
        data: {
          video_id: videoData?.id,
          entity_id: data?.entity_id,
          entity_description: data?.entity_description,
          language_code: "en-US",
          confidence: data?.confidence,
          left: data?.coordinates?.left,
          top: data?.coordinates?.top,
          right: data?.coordinates?.right,
          bottom: data?.coordinates?.bottom,
          start_time: data?.start_time,
          end_time: data?.end_time,
        },
        token: cookies.t,
      });
      setUnlistedModal({
        isActive: false,
      });
      getVideoDetail(id, cookies.t);
    } catch (error) {
      console.log(error);
    }
  };
  const [listesdModal, setListedModal] = useState({
    isActive: false,
    data: "",
    new_value: "",
  });
  const handleListedEdit = (data) => {
    setListedModal({
      isActive: true,
      data: data,
      description: data?.entity_description,
      title: data?.entity_description,
    });
  };
  const handelListedItemEdit = async (data, description) => {
    try {
      const response = await patchData({
        endpoint: "Video/editUnfilterData",
        data: {
          video_id: videoData?.id,
          filter_id: data?.id,
          entity_id: data?.entity_id,
          entity_description: listesdModal.description,
          language_code: "en-US",
          confidence: data?.confidence,
          left: data?.coordinates?.left,
          top: data?.coordinates?.top,
          right: data?.coordinates?.right,
          bottom: data?.coordinates?.bottom,
          start_time: data?.start_time,
          end_time: data?.end_time,
        },
        token: cookies.t,
      });
      console.log(data);
      setListedModal({
        ...listedData,
        isActive: false,
      });
      getVideoDetail(id, cookies.t);
    } catch (error) {
      console.log(error);
    }
  };

  // Add this code where you initialize your component or in a useEffect hook

  useEffect(() => {
    // Click event listener for the whole document body
    const handleClickOutside = (event) => {
      const isHandleVideoItemClicked = event.target.closest(".item") !== null;
      const isHandleUnfilteredDataClicked =
        event.target.closest(".unfiltered-items") !== null;

      // If neither handleVideoItem nor handleUnfilteredData was clicked, print to console
      if (!isHandleVideoItemClicked && !isHandleUnfilteredDataClicked) {
        setItemByClick(false);
      }
    };

    // Attach the event listener to the document body
    document.body.addEventListener("click", handleClickOutside);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <>
      <PageHeader title="View Video" />
      <div className="View-video-container">
        <div className="Video-player-left">
          {videoData && (
            <VideoPlr
              jsonData={itemByClick ? firstItem : videoData?.filtered_data}
              itemByClick={itemByClick}
              setItemByClick={setItemByClick}
              videoSrc={
                videoData
                  ? `https://storage.googleapis.com${videoData?.video_link}`
                  : "null"
              }
            />
          )}
        </div>
        <div className="Video-player-right">
          <PageCard classes="listed-items-container">
            <div className="listed-items">
              <div className="item-heading">Listed items</div>
              <div className="items-list-container">
                {listedData.map((item, index) => (
                  <div className="item d-flex" onClick={handleVideoItem}>
                    <div> {index + 1}.</div>
                    <div
                      key={index}
                      className="w-100 d-flex justify-content-between"
                    >
                      <div> {item?.entity_description}</div>
                      <span
                        className="edit-video-item-container"
                        onClick={() => handleListedEdit(item)}
                      >
                        <EditIcon className="edit-item-icon-container" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </PageCard>
          <PageCard classes="listed-items-container">
            <div className="unlisted-items">
              <div className="item-heading">Unlisted items</div>
              <div className="items-list-container">
                {unListedData?.map((item, index) => (
                  <div
                    className="item d-flex unfiltered-items"
                    onClick={handleUnfilteredData}
                  >
                    <div>{index + 1}.</div>
                    <div
                      key={index}
                      className="w-100 d-flex justify-content-between"
                    >
                      <div>{item?.entity_description}</div>
                      <span
                        className="edit-video-item-container"
                        onClick={() => handleUnfilteredEdit(item)}
                      >
                        <EditIcon className="edit-item-icon-container" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </PageCard>
        </div>
      </div>
      <LogoutModal
        isActive={unListesdModal.isActive}
        title={"Confirmation"}
        content={`Are you sure to move ${unListesdModal?.data?.entity_description} into Listed Data`}
        onClose={() => setUnlistedModal({ isActive: false })}
        onConfirm={() => handleMoveListed(unListesdModal?.data)}
      ></LogoutModal>
      <Modal
        isActive={listesdModal.isActive}
        title={`Edit Item ${listesdModal.title}`}
        onClose={() => setListedModal({ isActive: false })}
      >
        <InputLayout>
          <input
            type="text"
            value={listesdModal.description}
            onChange={(e) =>
              setListedModal({
                ...listesdModal, // Spread the existing state to keep other properties unchanged
                description: e.target.value, // Update the description with the new value
              })
            }
          />
          <div className="d-flex justify-content-end">
            <button
              className="btn"
              onClick={() =>
                setListedModal({ ...listesdModal, isActive: false })
              }
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() =>
                handelListedItemEdit(listesdModal.data, listedData.description)
              }
            >
              Confirm
            </button>
          </div>
        </InputLayout>
      </Modal>
    </>
  );
};

export default VideoView;
