import { Route, Navigate, Routes } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../store/slice/userData";

const ProtectedRoute = ({ children, ...rest }) => {
  const [cookies] = useCookies(["t", "auid"]); // Accessing the "t" cookie
  const dispatch = useDispatch();

  const isAuthenticated = !!cookies.t; // Checking if the "t" cookie exists

  useEffect(() => {
    dispatch(fetchUserData(cookies.auid));
  }, [dispatch, cookies.auid, isAuthenticated]);

  return isAuthenticated ? (
    <Routes>
      <Route {...rest}>{children}</Route>
    </Routes>
  ) : (
    <Navigate to="/" replace /> // Redirecting to the homepage ("/") if not authenticated
  );
};

export default ProtectedRoute;
