import React from 'react'
import Form from './Form'
import Heading from '../../../atoms/Heading'
import { useLocation } from 'react-router-dom'
import { useState , useEffect} from 'react'

const ResetPassword = ({type}) => {
  const location = useLocation();

  // Create a URLSearchParams object with the query parameters
  const params = new URLSearchParams(location.search);
  const [linkExpired , setLinkExpired] = useState(false)

  // Extract the user_id and expiry values
  const userId = params.get("user_id");
  const token = params.get("token");
  const expiry = new Date(params.get("expiry"));

  console.log('user_id', userId, 'expiry', expiry, 'token', token);

  // const checkValidity = async () => {
  //   const res = await postData('AdminAuth/checkLinkIsExpired', {}, {user_id : userId})

  //   if(res){
  //     console.log('res', res)
  //     if(res.response == 400){
  //       setLinkExpired(true)
  //     }
  //   }
  // }

  useEffect(() => {
    // checkValidity();
  }, [])



  // Compare the current time with the expiry time
  const currentTime = new Date();

  // Conditionally render the Form component
  const showForm = currentTime < expiry && !linkExpired;

  return (
    <div>
    {/* {showForm ? ( 
      <> */}
          <Heading title={type === 'reset' ? 'Reset Password' : 'Create Password'} description={`Enter a new password to ${type} the password on your account.`} /> 
          <Form expiry={expiry} uid={userId} token={token} type={type}/>
      {/* </>
    ) : (
      <Heading title='Link Expired' description={linkExpired ? 'This Link has already been expired contact Admin for a new link.' : 'This Link has already been expired please generate a new link.'} /> 
    ) */}
    
    {/* } */}
  </div>
  )
}

export default ResetPassword