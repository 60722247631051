import { useEffect, useRef, useState } from 'react';
import shoppingBagIcon from './shopping-bag.svg';
import './styles.css';
const VideoPlr=({jsonData,videoSrc,itemByClick,setItemByClick})=>{
  const [change,setChange]=useState(false);
  const [presentTime, setPresentTime] = useState(0);

// useEffect(() => {
//   // eslint-disable-next-line
//   const player = new Plyr("video", { captions: { active: true } });

//   // This function will be called whenever presentTime changes
//   const handlePresentTimeChange = (newPresentTime) => {
//     console.log("presentTime changed:", newPresentTime);

//     // Add any additional logic here based on the new presentTime value
//   };

//   // Initially call the handlePresentTimeChange function with the initial presentTime value
//   handlePresentTimeChange(player.currentTime);

//   // Set up a listener to track changes in presentTime
//   player.on("timeupdate", () => {
//     const newPresentTime = player.currentTime;
//     if (newPresentTime !== presentTime) {
//       setPresentTime(newPresentTime); // Update presentTime state
//       handlePresentTimeChange(newPresentTime); // Call the handler function
//     }
//   });

//   // Cleanup function
//   return () => {
//     player.off("timeupdate"); 
//   };
// }, []);  

useEffect(() => {
    // eslint-disable-next-line
    const player = new Plyr("video", { captions: { active: true } });

    // Set up Plyr player instance
    // debugger
    // if(player.currentTime > 5) 
    // player.pause();
    // player.once('ready', () => {
    //     setTimeout(() => {
    //       // Start the video at 10 seconds after a delay of 1 second
    //     //   player.play();
    //     }, 5000); // 1000 milliseconds = 1 second
    //   });
      

    // Update presentTime when Plyr's time updates
    player.on('timeupdate', () => {
      setPresentTime(player.currentTime);
    });

    return () => {
      player.destroy(); // Clean up Plyr player instance
    };
  }, []);

  useEffect(()=>{
  const overlay = document.getElementById("overlay");
  const imageContainer = document.getElementById("image-container");
  const toggleOverlayButton = document.getElementById("toggle-overlay-btn");
  // eslint-disable-next-line
  const player = new Plyr("video", { captions: { active: true } });

  if(change){
    overlay.className="visible";
    if (overlay.classList.contains("visible")) {
      // Pause the video when the overlay is shown
      player.pause();
    } else {
      // Clear the overlay when it is hidden
      imageContainer.innerHTML = "";
    }
  }
  else{
    if(overlay.classList.contains("visible") ) 
    overlay.classList.remove("visible");
  }
  window.player = player;
  },[change,presentTime])

  const handleChange=()=>{
    setChange((prev) => !prev)
  }

  useEffect(() => {
    // eslint-disable-next-line
  //   function customRound(decimal) {
  //     var integerPart = Math.floor(decimal);
  //     var fractionalPart = decimal - integerPart;
      
  //     if (fractionalPart < 0.25) {
  //         return integerPart;
  //     } else if (fractionalPart >= 0.25 && fractionalPart < 0.75) {
  //         return integerPart + 0.5;
  //     } else {
  //         return integerPart + 1;
  //     }
  // }
  console.log("json  : ",jsonData)
  function customRound(decimal) {
    var roundedTenths = Math.round(decimal * 2.5) / 2.5;
    return roundedTenths.toFixed(1); // Keep one decimal place
}
  // eslint-disable-next-line
    const player = new Plyr("video", { captions: { active: true } });
    if(itemByClick) player.pause();
    const imageContainer = document.getElementById("image-container");
    // const presentTime = Math.round(player.currentTime);
    const presentTime=player.currentTime;
    const decimalTime= customRound(player.currentTime)
    console.log(decimalTime,"---",player.currentTime)
    // Clear existing bounding boxes
    const clearBoundingBoxes = () => {
      imageContainer.innerHTML = "";
        const boundingBoxes = document.querySelectorAll('.bounding-box');
        const boundingBoxesDescription = document.querySelectorAll('.bounding-box-description');
        boundingBoxes.forEach(box => box.remove());
        boundingBoxesDescription.forEach(box=>box.remove());
    };

    clearBoundingBoxes(); // Clear existing bounding boxes
    imageContainer.innerHTML = "";
    jsonData?.forEach((objectAnnotation) => {
      const { coordinates, start_time,entity_description } = objectAnnotation;
  
      // Check if start_time_offset.nanos is 0 and presentTime is equal to start_time_offset.seconds
      // console.log("isCorrect   :",decimalTime >= presentTime );
      // start_time >= decimalTime && start_time <= presentTime
      // console.log(start_time.toFixed(5),decimalTime)
      if((presentTime < decimalTime ? start_time >= presentTime && start_time <= decimalTime : start_time >= decimalTime && start_time <= presentTime)) {  //1.5462 2                    //2.3490 2
          const videoContainer = document.getElementById("varun");
          const overlay = document.getElementById("overlay");

              const { left, top, right, bottom } = coordinates;
  
              // Show the bounding box
              const box = document.createElement("div");
              box.className = "bounding-box";
              box.style.left = `${left * 100}%`;
              box.style.top = `${top * 100}%`;
              box.style.width = `${(right - left) * 100}%`;
              box.style.height = `${(bottom - top) * 100}%`;
              box.style.position = "absolute";
              // box.style.border = "2px solid rgb(43, 255, 0)";
              box.style.boxSizing = "border-box";
              box.style.zIndex = "1000";

              // box.style.color="rgb(43, 255, 0)";
          //     const contentBox=document.createElement("div");
          //     contentBox.className="bounding-box-description";
          //     contentBox.innerHTML=`
          //     <p>${entity_description}</p>
          // `;
          const childDiv = document.createElement("div");
          childDiv.className="bound-box-child-container";
          box.appendChild(childDiv);
          document.body.appendChild(box);
              // contentBox.style.top = `calc(${top * 100}% - 24px)`;
              // contentBox.style.left = `${left * 100}%`;
              // contentBox.style.position="absolute";
              // contentBox.style.boxSizing = "border-box";
              // contentBox.style.zIndex = "1";
              // contentBox.style.color="yellow";
              box.setAttribute("data-description", entity_description);
              const uniqueProducts = new Set(); 
              if (!uniqueProducts.has(entity_description)) {
                  const item = document.createElement("div");
                  item.classList.add("overlay-item");
                  item.innerHTML = `
                      <p>Product : ${entity_description}</p>
                  `;
                  imageContainer.appendChild(item);
                  uniqueProducts.add(entity_description);
              }
              if (videoContainer) {
                // videoContainer.appendChild(contentBox);
                  videoContainer.appendChild(box);
                  // console.log(videoContainer.contains(box));
              } else {
                  console.log("vjs-text-track-display element not found");
              }
          // <-- Added closing bracket for forEach loop over annotations
      }
      // setItemByClick(false)
  });
  
}, [jsonData, presentTime]);



//    useEffect(()=>{
//       const videoContainer=document.getElementById('varun');
//       const videoContainerStyles = videoContainer.getBoundingClientRect();
//       if(videoContainerStyles.width > 440 || videoContainerStyles.height > 260){
//         videoContainer.style.scale=0.4;
//       }
//       console.log(videoContainerStyles)
//    },[])
    // console.log(jsonData?.object_annotations)

    const videoRef = useRef(null);

    const handleClick = (event) => {
        // Get the coordinates of the click event
        const clickX = event.clientX;
        const clickY = event.clientY;

        // Get the position of the video player relative to the viewport
        const playerRect = videoRef.current.getBoundingClientRect();
        const playerX = playerRect.left;
        const playerY = playerRect.top;

        // Calculate the click coordinates relative to the video player
        const playerClickX = clickX - playerX;
        const playerClickY = clickY - playerY;

        // Output the coordinates
        console.log('Click coordinates relative to video player: (' + playerClickX + ', ' + playerClickY + ')');
    };




  console.log(presentTime)
    return(
      <>
        <div className="container" id='video-player'>
          <button id="toggle-overlay-btn" onClick={() => handleChange()}>
            <img src={shoppingBagIcon} alt="Overlay Icon" />
          </button>
          <div id="varun" style={{ position: "relative" }}>
          {videoSrc && (
            <video controls crossOrigin id="video-player" width="440" height="260" ref={videoRef} onClick={handleClick}>
              <source src={videoSrc} type='video/mp4' />
              <source src={videoSrc} type="video/mp4" size="1080" />
            </video>
             )}
          </div>
          <div id="overlay">
            <div className="overlay-heading">X-Ray</div>
            <div id="image-container"></div>
          </div>
        </div>
    </>
)
}
export default VideoPlr;


