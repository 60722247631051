import { createSlice } from "@reduxjs/toolkit";
import { getData } from "../../services";

export const peopleSlice = createSlice({
  name: "peopleData",
  initialState: {
    peopleData: {},
  },
  reducers: {
    setPeopleData: (state, action) => {
      state.peopleData = action.payload;
    },
  },
});

export const { setPeopleData } = peopleSlice.actions;
export const stateSelector = (state) => state?.peopleSlice?.peopleData;

export const fetchUserData = (cuid) => async (dispatch) => {
  try {
    const res = await getData({
      endpoint: "BusinessUser/getUserProfile",
      params: { user_id: cuid },
    });

    dispatch(setPeopleData(res));
  } catch (error) {}
};
export default peopleSlice.reducer;
