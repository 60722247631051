import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../atoms/PageHeader";
import ExcelUpload from "./ExcelUpload";
import VideoUpload from "./VideoUpload";
import { useCookies } from "react-cookie";
import "./styles.scss";
import { postFormData } from "../../../../services";
import Modal from "../../../atoms/Modals/Modal";
import EyeIcon from "../../../../components/icons/eyeIcon";

const AddVideos = () => {
  const [cookies] = useCookies(["t"]);
  const [excelFile, setExcelFile] = useState(null);
  const [videoData, setVideoData] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const navigate = useNavigate();
  const handleVideoSubmit = async (file) => {
    const formData = new FormData();
    setIsModalActive(true);
    formData.append("videoFile", videoData);
    formData.append("formFile", excelFile);

    try {
      const res = await postFormData(
        "Video/storeVideoDetails",
        formData,
        {},
        cookies.t
      );
      console.log(res);
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };
  const handleModalClose = () => {
    setIsModalActive(false);
    navigate(-1);
  };

  return (
    <>
      <PageHeader title={"Add Video"} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <VideoUpload videoData={videoData} setVideoData={setVideoData} />
        <ExcelUpload excelFile={excelFile} setExcelFile={setExcelFile} />
      </div>
      <footer className="footer">
        {false ? (
          <button
            type="submit"
            className={`btn btn-success px-4 `}
            onClick={() => handleVideoSubmit}
            disabled={!videoData || !excelFile}
          >
            Edit Video
          </button>
        ) : (
          <button
            type="submit"
            className={`btn btn-success px-4 `}
            onClick={handleVideoSubmit}
            disabled={!videoData || !excelFile}
          >
            Add Video
          </button>
        )}
      </footer>
      <Modal isActive={isModalActive} noHeader={true}>
        <div>
          The video was processing;we will notify you once it got finished
        </div>
        <div>
          You can also get to know the Eye Icon ({" "}
          <span>
            <EyeIcon />
          </span>{" "}
          ) will get display once Your video was ready to go!
        </div>
        <div className="w-100 d-flex justify-content-end">
          <button className={`btn btn-success px-4`} onClick={handleModalClose}>
            ok
          </button>
        </div>
      </Modal>
    </>
  );
};
export default AddVideos;
