import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Search from "../../../atoms/Search";
import DeleteIcon from "../../../icons/deleteIcon";
import EyeIcon from "../../../icons/eyeIcon";
import PageCard from "../../../atoms/PageCard";
import CustomDataGrid from "../../../atoms/CustomDataGrid";
import "./styles.scss";
import EditIcon from "../../../icons/editIcon";
import TabStrip from "../../../atoms/TabStrip/TabStrip";
import Pagination from "../../../atoms/Pagination";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard/index.tsx";
import { getData } from "../../../../services/index.js";
import { postData } from "../../../../services/index.js";
import { postFormData } from "../../../../services/index.js";
import {patchData, patchFormData} from "../../../../services/index.js";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';


const PreviewDashboard = ({
  CartData,
  DesctiptionData,
  hightLightData,
  collectionData,
  catalogueData,
  setCartData,
  setDesctiptionData,
  setHightLightData,
  searchValuedesc,
  setSearhValuedesc,
  searchValuecart,
  setSearhValuecart,
  searchValuehigh,
  setSearhValuehigh,
  setActiveTab,
  activeTab,
  videoRef,
  setModalState,
  setEditModalState,
  setChooseCoordinates,
  handleItemsPerPageChange,
  handlePageChange,
  itemsPerPage,
  currentPage,
  totalDescriptionItem,
  TotalHighlightItem,
  TotalCatalogueItem,
  totalCartItem,
  itemsPerPageHighlight,
  currentPageHighlight,
  itemsPerPageCart,
  currentPageCart,
  handleItemsPerPageChangeHighlight,
  handlePageChangeHighlight,
  handleItemsPerPageChangeCart,
  handlePageChangeCart,
  handleItemsPerPageChangeCatalogue,
  handlePageChangeCatalogue,
  totalCatalogueItem,
  currentPageCatalogue,
  itemsPerPageCatalogue,
  setDeleteModal,
  handlePubishButton,
  setHoverItem,
  videoDataPreviw,
  getCatalogueDetails,
  videoData,
}) => {

  
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchValueCatalogue, setSearchValueCatalogue] = useState('');
  const [cookies] = useCookies(["t", "auid"])

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };
  // console.log("active", activeTab);

  const onTabChange = (event, value) => {
    setActiveTab(value);
 
    if (value === "Description") {
      setSearhValuedesc(null);
    }
 
    else if (value === "Cart") {
      setSearhValuecart(null);
    }
    else if (value === "HighLight") {
      setSearhValuehigh(null);
    }
    else {
      setSearchValueCatalogue("");
    }
  };

  

  /* -------------------- Selecting all the Checkboxes for Copying item ----------------*/
  
  const [selectAllCart, setSelectAllCart] = useState(false);
  const [selectAllHighlight, setSelectAllHighlight] = useState(false);
  const [selectAllDescription, setSelectAllDescription] = useState(false);

  const handleSelectAllChange = (field) => {
    if (field === "cart") {
      const newValue = !selectAllCart;
      setSelectAllCart(newValue);
      setSelectedRows((prevState) => {
        const updatedRows = { ...prevState };
        filteredCatalogueData.forEach((row) => {
          updatedRows[row.id] = {
            ...updatedRows[row.id],
            cart: newValue,
          };
        });
        return updatedRows;
      });
    } else if (field === "highlight") {
      const newValue = !selectAllHighlight;
      setSelectAllHighlight(newValue);
      setSelectedRows((prevState) => {
        const updatedRows = { ...prevState };
        filteredCatalogueData.forEach((row) => {
          updatedRows[row.id] = {
            ...updatedRows[row.id],
            highlight: newValue,
          };
        });
        return updatedRows;
      });
    } else if (field === "description") {
      const newValue = !selectAllDescription;
      setSelectAllDescription(newValue);
      setSelectedRows((prevState) => {
        const updatedRows = { ...prevState };
        filteredCatalogueData.forEach((row) => {
          updatedRows[row.id] = {
            ...updatedRows[row.id],
            description: newValue,
          };
        });
        return updatedRows;
      });
    }
  };


  /* -------------------- Copying the Data from Catalogue tab ----------------------*/

  const [existingItems, setExistingItems] = useState({ descriptions: [], carts: [], highlights: [] });

  


  

  const [selectedRows, setSelectedRows] = useState({});

  const handleCheckboxChange = (id, field) => {
    setSelectedRows((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: !prevState[id]?.[field],
      },
    }));
  };

  const formatTime = (time) => {
  if (typeof time === 'number') {
    const seconds = Math.floor(time / 10);
    const deciseconds = time % 10;
    const date = new Date(seconds * 1000);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const secs = String(date.getUTCSeconds()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}:${secs}.${deciseconds}`;
    return formattedTime;
  }
  return time;
};



useEffect(() => {
  const fetchExistingItems = async () => {
    try {
      const [descriptions, carts, highlights] = await Promise.all([
        getData({ endpoint: "VideoProduct/getAllProductDescription", params: { video_id: id }, token: cookies.t }),
        getData({ endpoint: "VideoProduct/getAllProductCards", params: { video_id: id }, token: cookies.t }),
        getData({ endpoint: "VideoProduct/getAllHighlights", params: { video_id: id }, token: cookies.t })
      ]);

      setExistingItems({
        descriptions: descriptions.data || [],
        carts: carts.data || [],
        highlights: highlights.data || []
      });
    } catch (error) {
      console.error("Error fetching existing items:", error);
    }
  };

  fetchExistingItems();
}, [id, cookies.t]);




const isItemCopied = (name, startTime, endTime, field) => {
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);

  const copied = existingItems[field].some(item => {
    const formattedItemStartTime = formatTime(item.start_time);
    const formattedItemEndTime = formatTime(item.end_time);
    return item.name === name && formattedItemStartTime === formattedStartTime && formattedItemEndTime === formattedEndTime;
  });

  return copied;
};





  const handleApplyButton = async () => {
    const updatedDescriptions = [];
    const updatedCarts = [];
    const updatedHighlights = [];
    
    const formatTime = (deciseconds) => {
      const totalSeconds = deciseconds / 10;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = (totalSeconds % 60).toFixed(1);
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(4, '0')}`;
    };
  
    const addToUpdateList = (dataToCopy, targetList, updatedList, listName) => {
      const itemExists = targetList.some(existingItem => 
        existingItem.name === dataToCopy.name && 
        existingItem.start_time === dataToCopy.start_time && 
        existingItem.end_time === dataToCopy.end_time
      );
    
      console.log(`Checking if item exists in ${listName}:`, dataToCopy);
      console.log('Item exists:', itemExists);
    
      if (!itemExists) {
        updatedList.push(dataToCopy);
        console.log(`Item added to ${listName}:`, dataToCopy);
      }
    };
  
    filteredCatalogueData.forEach((row) => {
      const selectedFields = selectedRows[row.id];
      const startTime = !isNaN(row.startTime) ? formatTime(row.startTime) : row.startTime;
      const endTime = !isNaN(row.endTime) ? formatTime(row.endTime) : row.endTime;
    
      if (selectedFields?.description) {
        const dataToCopy = {
          name: row.description,
          start_time: startTime,
          end_time: endTime,
          x_coordinate: row.x,
          y_coordinate: row.y,
          photo: row.photo || "https://via.placeholder.com/50",
          description: row.description || "Product's Description",
          video_id: id,
          link: row.link,
          user_id: cookies.auid,
        };
        addToUpdateList(dataToCopy, DesctiptionData.concat(updatedDescriptions), updatedDescriptions, 'Descriptions');
      }
      if (selectedFields?.cart) {
        const dataToCopy = {
          id: uuidv4(),
          name: row.description,
          start_time: startTime,
          end_time: endTime,
          x_coordinate: row.x,
          y_coordinate: row.y,
          photo: row.photo || "https://via.placeholder.com/50",
          link: row.link,
          product_cta: row.product_cta || "Buy Now",
          video_id: id, 
          price: row.price || 0,
        };
        addToUpdateList(dataToCopy, CartData.concat(updatedCarts), updatedCarts, 'Carts');
      }
      if (selectedFields?.highlight) {
        const dataToCopy = {
          name: row.description,
          start_time: startTime,
          end_time: endTime,
          x_coordinate: row.x,
          y_coordinate: row.y,
          video_id: id,
          link: row.link,
          user_id: cookies.auid,
        };
        addToUpdateList(dataToCopy, hightLightData.concat(updatedHighlights), updatedHighlights, 'Highlights');
      }
    });
  
    try {
      for (const description of updatedDescriptions) {
        const res = await postFormData('VideoProduct/addProductDescription', description, {}, cookies.t);
      }
    
      for (const cart of updatedCarts) {
        const res = await postFormData('VideoProduct/addProductCard', cart, {}, cookies.t);
      }
    
      for (const highlight of updatedHighlights) {
        const res = await postData({
            endpoint: "VideoProduct/addProductHighlight",
            data:highlight,
            params: {},
            token: cookies.t,
          });
        
      }
    
      toast.success("Item Copied Successfully", {
        style: {
          border: '1px solid #4CAF50',
          padding: '16px',
          color: '#4CAF50',
          fontSize: '16px',
          backgroundColor: '#ffffff'
        },
        iconTheme: {
          primary: '#4CAF50',
          secondary: '#FFFFFF',
        },
      });
      
      console.log('Data successfully copied and saved to the server.');
    
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error("Error copying item", {
        style: {
          border: '1px solid #FF0000',
          padding: '16px',
          color: '#FF0000',
          fontSize: '16px',
          backgroundColor: '#ffffff'
        },
        iconTheme: {
          primary: '#FF0000',
          secondary: '#FFFFFF',
        },
      });
    }
  };



  /* ------------------- Excel Download Logic ------------------------ */
  const [exportMessage, setExportMessage] = useState("");
  const [showExportMessage, setShowExportMessage] = useState(false);


  const handleExportAsExcel = async () => {
    let dataToExport = [];

    const filterColumns = (data) => {
      return data.map(item => ({
        id: item.id,
        name: item.name,
        link: item.link
      }));
    };

    if (activeTab === "Description") {
      dataToExport = filterColumns(DesctiptionData);
    } else if (activeTab === "Cart") {
      dataToExport = filterColumns(CartData);
    } else if (activeTab === "HighLight") {
      dataToExport = filterColumns(hightLightData);
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    worksheet.columns = [
      { header: 'ID', key: 'id', width: 30 },
      { header: 'Name', key: 'name', width: 30 },
      { header: 'Link', key: 'link', width: 30 }
    ];

    dataToExport.forEach(item => {
      worksheet.addRow(item);
    });

    // Adding hidden row with unique identifier
    const identifierRow = worksheet.addRow(['UniqueIdentifier', activeTab]);
    identifierRow.hidden = true;

    // Protect the entire worksheet first
    await worksheet.protect('hardcoded_password', {
      selectLockedCells: true,
      selectUnlockedCells: true
    });

    // Unlock the name and link columns
    worksheet.getColumn('name').eachCell((cell, rowNumber) => {
      if (rowNumber > 1) { // Skip header row
        cell.protection = { locked: false };
      }
    });

    worksheet.getColumn('link').eachCell((cell, rowNumber) => {
      if (rowNumber > 1) { // Skip header row
        cell.protection = { locked: false };
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${activeTab.toLowerCase()}_data_${new Date().toISOString().split('T')[0]}.xlsx`;
    link.click();
    alert("Your data has been exported successfully. Please note that this file is optimized for Microsoft Excel. If you update it in another application, some features might not work as expected.");
  };



  const handleImportExcel = async (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const [importedTab] = fileName.split('_data_');

    if (importedTab !== activeTab.toLowerCase()) {
        alert('This file is not valid for the current tab.');
        return;
    }

    if (!window.confirm(`You are about to import data into the ${activeTab} tab. Do you want to proceed?`)) {
        return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Read with header as an array

        // Check the unique identifier
        const identifierRow = importedData[importedData.length - 1];
        const [identifierKey, identifierValue] = identifierRow;

        if (identifierKey !== 'UniqueIdentifier' || identifierValue !== activeTab) {
            alert('This file is not valid for the current tab.');
            return;
        }

        // Skip the first row (header) and the identifier row
        const normalizedData = importedData.slice(1, -1).map((row) => ({
            id: row[0],
            name: row[1],
            link: row[2],
        }));

        console.log("Normalized Data:", normalizedData);

        try {
            if (activeTab === "Description") {
                const updatedDescriptions = DesctiptionData.map(item => {
                    const updatedItem = normalizedData.find(row => row.id === item.id);
                    return updatedItem ? { ...item, ...updatedItem } : item;
                });
                for (const description of updatedDescriptions) {
                    const res = await patchFormData('VideoProduct/editProductDescription', description, {}, cookies.t);
                    console.log("Description Saved:", res);
                }
                setDesctiptionData(updatedDescriptions); // Update state after saving to DB
                console.log("Updated Descriptions:", updatedDescriptions);
            } else if (activeTab === "Cart") {
                const updatedCarts = CartData.map(item => {
                    const updatedItem = normalizedData.find(row => row.id === item.id);
                    return updatedItem ? { ...item, ...updatedItem } : item;
                });
                for (const cart of updatedCarts) {
                    const res = await patchFormData('VideoProduct/editProductCard', cart, {}, cookies.t);
                    console.log("Cart Saved:", res);
                }
                setCartData(updatedCarts); // Update state after saving to DB
                console.log("Updated Carts:", updatedCarts);
            } else if (activeTab === "HighLight") {
                const updatedHighlights = hightLightData.map(item => {
                    const updatedItem = normalizedData.find(row => row.id === item.id);
                    return updatedItem ? { ...item, ...updatedItem } : item;
                });
                for (const highlight of updatedHighlights) {
                    const res = await patchData({
                        endpoint: "VideoProduct/editProductHighlight",
                        data: highlight,
                        params: {},
                        token: cookies.t,
                    });
                    console.log("Highlight Saved:", res);
                }
                setHightLightData(updatedHighlights); // Update state after saving to DB
                console.log("Updated Highlights:", updatedHighlights);
            }

            toast.success("Data imported and saved successfully!", {
                style: {
                    border: '1px solid #4CAF50',
                    padding: '16px',
                    color: '#4CAF50',
                    fontSize: '16px',
                    backgroundColor: '#ffffff',
                    minWidth: '250px',
                },
                iconTheme: {
                    primary: '#4CAF50',
                    secondary: '#FFFFFF',
                },
            });
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error("Error importing data", {
                style: {
                    border: '1px solid #FF0000',
                    padding: '16px',
                    color: '#FF0000',
                    fontSize: '16px',
                    backgroundColor: '#ffffff',
                    minWidth: '250px',
                },
                iconTheme: {
                    primary: '#FF0000',
                    secondary: '#FFFFFF',
                },
            });
        }
    };
    reader.readAsArrayBuffer(file);
};


  /* ----------------------Regarding HighLights Item---------------------*/

  const handleDeleteHighLight = (data) => {
    setDeleteModal(() => ({
      isModalOpen: true,
      data: data,
      title: "Highlight",
    }));
  };

  const handleViewHighLight = (data) => {
    window.scrollTo(0, 0);
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }

    const startTime = calculateTotalSeconds(data?.start_time); // Calculate start time including deciseconds
    if (videoRef.current) {
        videoRef.current.currentTime = startTime; // Set the video current time to the calculated start time
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {searchValuecart || searchValuedesc || searchValuehigh ? (
          <NoContentCard title="No Item Found" type="Search" />
        ) : (
          <NoContentCard title="No Items Added Yet!" type="Search" />
        )}
      </>
    );
  });

  const handleHightlightEdit = (hightLightEditData) => {
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"

      if (!timeString) {
        console.error("Invalid timeString:", timeString);
        return 0; // Default value
      }
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(hightLightEditData?.start_time);
    videoRef.current.currentTime = startTime;
    setHoverItem(() => ({
      positionx: hightLightEditData?.x_coordinate,
      positiony: hightLightEditData?.y_coordinate,
    }));
    setEditModalState(() => ({
      isModalOpen: true,
      name: "Update Highlight Widget",
      data: hightLightEditData,
    }));
    videoRef.current.pause();
    setChooseCoordinates(true);
  };
  const highLightcolumns = [
    {
      field: "name",
      headerName: "Item Name",
      flex: 1.5,
      renderCell: (params) => {
        return <div>{params.row.name}</div>;
      },
    },
    {
      field: "created by",
      headerName: "Created By",
      flex: 1.5,
      renderCell: (params) => {
        return <div className="ms-1">{params.row.created_by}</div>;
      },
    },
    {
      field: "time", 
      headerName: "Duration",
      flex: 1,
      renderCell: (params) => {
        const calculateTotalSeconds = (timeString) => {
          const parts = timeString.split(':');
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          const secondsAndDeciseconds = parts[2].split('.');
          const seconds = parseInt(secondsAndDeciseconds[0], 10);
          const deciseconds = secondsAndDeciseconds.length > 1 ? parseInt(secondsAndDeciseconds[1], 10) / 10 : 0;
          return hours * 3600 + minutes * 60 + seconds + deciseconds;
        };
      
        const handleTimeClick = (timeString) => {
          const timeInSeconds = calculateTotalSeconds(timeString);
          if (videoRef.current) {
            videoRef.current.currentTime = timeInSeconds;
          }
        };
      
        return (
          <div>
            <span 
              onClick={() => handleTimeClick(params.row.start_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '5px' }}
            >
              {params.row.start_time}
            </span>
            -
            <span 
              onClick={() => handleTimeClick(params.row.end_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}
            >
              {params.row.end_time}
            </span>
          </div>
        );
      },
    },    

    // {
    //   field: "videodate",
    //   headerName: "Created At",
    //   flex: 1.5,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params?.row?.created_date
    //           ? moment(params?.row?.created_date).format("Do MMM YY, hh:mm a")
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              className="me-2"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                handleHightlightEdit(params?.row);
              }}
            >
              <EditIcon />
            </span>
            <span
              type="button"
              className="me-2 preview-Add-icon"
              onClick={() => handleViewHighLight(params?.row)}
            >
              <EyeIcon />
            </span>
            <span
              type="button"
              onClick={() => handleDeleteHighLight(params?.row?.id)}
            >
              <DeleteIcon />
            </span>
          </>
        );
      },
    },
  ];

  const handleDeleteCart = (data) => {
    setDeleteModal(() => ({
      isModalOpen: true,
      data: data,
      title: "Cart",
    }));
  };
  const handleViewCart = (data) => {
    window.scrollTo(0, 0);
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
      if (!timeString) {
          console.error("Invalid timeString:", timeString);
          return 0; // Default value
        }
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(data?.start_time);
    videoRef.current.currentTime = startTime;
  };

  const cartcolumns = [
    {
      field: "name",
      headerName: "Item Name",
      flex: 1.5,
      renderCell: (params) => {
        return <div className="description-truncate">{params.row.name}</div>;
      },
    },
    {
      field: "created by",
      headerName: "Created By",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className=" ms-1 description-truncate">
            {params.row.created_by}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Item Price",
      flex: 1.5,
      renderCell: (params) => {
        return <div className="ms-3">{params.row.price}</div>;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="description-truncate">{params.row.description}</div>
        );
      },
    },
    {
      field: "time", 
      headerName: "Duration",
      flex: 1,
      renderCell: (params) => {
        const calculateTotalSeconds = (timeString) => {
          const parts = timeString.split(':');
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          const secondsAndDeciseconds = parts[2].split('.');
          const seconds = parseInt(secondsAndDeciseconds[0], 10);
          const deciseconds = secondsAndDeciseconds.length > 1 ? parseInt(secondsAndDeciseconds[1], 10) / 10 : 0;
          return hours * 3600 + minutes * 60 + seconds + deciseconds;
        };
      
        const handleTimeClick = (timeString) => {
          const timeInSeconds = calculateTotalSeconds(timeString);
          if (videoRef.current) {
            videoRef.current.currentTime = timeInSeconds;
          }
        };
      
        return (
          <div>
            <span 
              onClick={() => handleTimeClick(params.row.start_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '5px' }}
            >
              {params.row.start_time}
            </span>
            -
            <span 
              onClick={() => handleTimeClick(params.row.end_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}
            >
              {params.row.end_time}
            </span>
          </div>
        );
      },
    },
    // {
    //   field: "videodate",
    //   headerName: "Created At",
    //   flex: 1.5,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params?.row?.created_date
    //           ? moment(params?.row?.created_date).format("Do MMM YY, hh:mm a")
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              className="me-2"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                handlecartsEdit(params?.row);
              }}
            >
              <EditIcon />
            </span>
            <span
              type="button"
              className="me-2 preview-Add-icon"
              onClick={() => handleViewCart(params?.row)}
            >
              <EyeIcon />
            </span>
            <span
              type="button"
              onClick={() => handleDeleteCart(params?.row?.id)}
            >
              <DeleteIcon />
            </span>
          </>
        );
      },
    },
  ];

  const handlecartsEdit = (cartsData) => {
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
      if (!timeString) {
          console.error("Invalid timeString:", timeString);
          return 0; // Default value
        }
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(cartsData?.start_time);
    videoRef.current.currentTime = startTime;
    setHoverItem(() => ({
      positionx: cartsData?.x_coordinate,
      positiony: cartsData?.y_coordinate,
    }));
    setEditModalState(() => ({
      isModalOpen: true,
      name: "Update Cart Widget",
      data: cartsData,
    }));
    videoRef.current.pause();
    setChooseCoordinates(true);
  };

  /* -------------------------------------------------- */

  /* ----------------------Regarding Cart Item---------------------*/

  const handleDeleteDescription = (data) => {
    setDeleteModal(() => ({
      isModalOpen: true,
      data: data,
      title: "Description",
    }));
  };

  const handleViewDescription = (data) => {
    window.scrollTo(0, 0);
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(data?.start_time);
    videoRef.current.currentTime = startTime;
  };

  const descriptioncolumns = [
    {
      field: "name",
      headerName: "Item Name",
      flex: 1.5,
      renderCell: (params) => {
        return <div className="description-truncate">{params.row.name}</div>;
      },
    },
    {
      field: "created by",
      headerName: "Created By",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className=" ms-1 description-truncate">
            {params.row.created_by}
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="description-truncate">{params.row.description}</div>
        );
      },
    },
    {
      field: "time", 
      headerName: "Duration",
      flex: 1,
      renderCell: (params) => {
        const calculateTotalSeconds = (timeString) => {
          const parts = timeString.split(':');
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          const secondsAndDeciseconds = parts[2].split('.');
          const seconds = parseInt(secondsAndDeciseconds[0], 10);
          const deciseconds = secondsAndDeciseconds.length > 1 ? parseInt(secondsAndDeciseconds[1], 10) / 10 : 0;
          return hours * 3600 + minutes * 60 + seconds + deciseconds;
        };
      
        const handleTimeClick = (timeString) => {
          const timeInSeconds = calculateTotalSeconds(timeString);
          if (videoRef.current) {
            videoRef.current.currentTime = timeInSeconds;
          }
        };
      
        return (
          <div>
            <span 
              onClick={() => handleTimeClick(params.row.start_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '5px' }}
            >
              {params.row.start_time}
            </span>
            -
            <span 
              onClick={() => handleTimeClick(params.row.end_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}
            >
              {params.row.end_time}
            </span>
          </div>
        );
      },
    },
    // {
    //   field: "videodate",
    //   headerName: "Created At",
    //   flex: 1.5,
    //   renderCell: (params) => {
    //     return (
    //       <div className="description-truncate">
    //         {params?.row?.created_date
    //           ? moment(params?.row?.created_date).format("Do MMM YY, hh:mm a")
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              className="me-2"
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
                // handleViewDescription(params?.row);
                handleDescriptionEdit(params?.row);
              }}
            >
              <EditIcon />
            </span>
            <span
              type="button"
              className="me-2 preview-Add-icon"
              onClick={() => handleViewDescription(params?.row)}
            >
              <EyeIcon />
            </span>
            <span
              type="button"
              onClick={() => handleDeleteDescription(params?.row?.id)}
            >
              <DeleteIcon />
            </span>
          </>
        );
      },
    },
  ];


  const handleDescriptionEdit = (descriptionData) => {
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
    if (!timeString) {
        console.error("Invalid timeString:", timeString);
        return 0; // Default value
      }
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(descriptionData?.start_time);
    videoRef.current.currentTime = startTime;
    console.log("descriptionData", descriptionData);
    setHoverItem(() => ({
      positionx: descriptionData?.x_coordinate,
      positiony: descriptionData?.y_coordinate,
    }));
    setEditModalState(() => ({
      isModalOpen: true,
      name: "Update Description Widget",
      data: descriptionData,
    }));
    videoRef.current.pause();
    setChooseCoordinates(true);
  };

  const handleDeleteCollection = (data) => {
    setDeleteModal(() => ({
      isModalOpen: true,
      data: data,
      title: "Collection",
    }));
  };

  const handleCollectionEdit = async (collectionData) => {
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(collectionData?.start_date);
    videoRef.current.currentTime = startTime;
    setEditModalState(() => ({
      isModalOpen: true,
      name: "Update Collection Widget",
      data: collectionData,
    }));
    videoRef.current.pause();
    setChooseCoordinates(true);
    setHoverItem(() => ({
      positionx: collectionData?.x_coordinate,
      positiony: collectionData?.y_coordinate,
    }));
    console.log("collection", collectionData);
  };

  const collectionDataColumn = [
    {
      field: "name",
      headerName: "Item Name",
      flex: 1.5,
      renderCell: (params) => {
        return <div className="description-truncate">{params.row.name}</div>;
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className=" ms-1 description-truncate">
            {params.row.created_by}
          </div>
        );
      },
    },
    {
      field: "time", 
      headerName: "Duration",
      flex: 1,
      renderCell: (params) => {
        const calculateTotalSeconds = (timeString) => {
          const parts = timeString.split(':');
          const hours = parseInt(parts[0], 10);
          const minutes = parseInt(parts[1], 10);
          const secondsAndDeciseconds = parts[2].split('.');
          const seconds = parseInt(secondsAndDeciseconds[0], 10);
          const deciseconds = secondsAndDeciseconds.length > 1 ? parseInt(secondsAndDeciseconds[1], 10) / 10 : 0;
          return hours * 3600 + minutes * 60 + seconds + deciseconds;
        };
      
        const handleTimeClick = (timeString) => {
          const timeInSeconds = calculateTotalSeconds(timeString);
          if (videoRef.current) {
            videoRef.current.currentTime = timeInSeconds;
          }
        };
      
        return (
          <div>
            <span 
              onClick={() => handleTimeClick(params.row.start_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '5px' }}
            >
              {params.row.start_time}
            </span>
            -
            <span 
              onClick={() => handleTimeClick(params.row.end_time)} 
              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginLeft: '5px' }}
            >
              {params.row.end_time}
            </span>
          </div>
        );
      },
    },
    // {
    //   field: "videodate",
    //   headerName: "Created At",
    //   flex: 1.5,
    //   renderCell: (params) => {
    //     return (
    //       <div className="description-truncate">
    //         {params?.row?.created_date
    //           ? moment(params?.row?.created_date).format("Do MMM YY, hh:mm a")
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              className="me-2"
              onClick={() => {
                handleCollectionEdit(params?.row);
                // console.log("params", params.row);
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              <EditIcon />
            </span>
            <span
              type="button"
              className="me-2 preview-Add-icon"
              // onClick={() => handleViewDescription(params?.row)}
            >
              <EyeIcon />
            </span>
            <span
              type="button"
              onClick={() => handleDeleteCollection(params?.row?.id)}
            >
              <DeleteIcon />
            </span>
          </>
        );
      },
    },
  ];


 const handleCatalogueEdit = (catalogueData) => {
  // const startTime = calculateTotalSeconds(catalogueData?.startTime);
  // videoRef.current.currentTime = startTime;
  setHoverItem(() => ({
    positionx: catalogueData?.x,
    positiony: catalogueData?.y,
  }));

  setEditModalState(() => ({
    isModalOpen: false,
    name: 'Update Catalogue Widget',
    data: catalogueData,
  }));
  videoRef.current.pause();
  setChooseCoordinates(true);
};

  const handleViewCatalogue = (data) => {
    if (videoRef.current) {
    videoRef.current.currentTime = data.startTime / 10;
    videoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  };

  const handleDeleteCatalogue = (id) => {
    setDeleteModal(() => ({
      isModalOpen: true,
      data: id,
      title: 'Catalogue',
    }));
  };

  const filteredCatalogueData = catalogueData.filter((item) =>
    item.description.includes(searchValueCatalogue) || 
    item.description.toLowerCase().includes(searchValueCatalogue.toLowerCase()) || 
    item.description.toUpperCase().includes(searchValueCatalogue.toUpperCase())
);



  const catalogueColumns = [
  {
    field: 'description',
    headerName: 'Item Name',
    flex: 1.5,
  },


  {
  field: 'duration',
    headerName: 'Duration',
    flex: 1,
    renderCell: (params) => {
      const handleTimeClick = (time) => {
        if (videoRef.current) {
          videoRef.current.currentTime = time / 10;
        }
      };
    
      const formatTime = (deciseconds) => {
        const totalSeconds = deciseconds / 10;
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = (totalSeconds % 60).toFixed(1);
      
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(4,    '0')}`;
      };
    
      return (
        <div>
          <span
            onClick={() => handleTimeClick(params.row.startTime)}
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '5px' }}
          >
            {formatTime(params.row.startTime)}
          </span>
      
          <span>-</span>
      
          <span
            onClick={() => handleTimeClick(params.row.endTime)}
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '5px' }}
          >
            {formatTime(params.row.endTime)}
          </span>
        </div>
      );
    },
  },

  {
    field: 'cart',
    headerName: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>Cart</span>
        <input
          type="checkbox"
          checked={selectAllCart}
          onChange={() => handleSelectAllChange("cart")}
          style={{ marginLeft: '5px' }}  // Adjust the margin as needed
        />
      </div>
    ),
    flex: 1,
    renderCell: (params) => {
    const name = params.row.description || params.row.name; // Ensure description or name is used
    const start_time = params.row.startTime || params.row.start_time; // Ensure startTime or start_time is used
    const end_time = params.row.endTime || params.row.end_time; // Ensure endTime or end_time is used
    const isCopied = isItemCopied(name, start_time, end_time, 'carts');
      return (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.id]?.cart || false}
            onChange={() => handleCheckboxChange(params.row.id, "cart")}
            style={{ color: isCopied ? 'green' : 'blue' }}
          />
          {isCopied && <span title="Item copied to Cart" style={{ color: 'green', marginLeft: '5px', position: 'relative', top: '-20px'}}>✓</span>}
        </div>
      );
    },
  },

  ...(videoData.purpose === 'Advertisement' ? [{
    field: 'descriptiontab',
    headerName: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>Description</span>
        <input
          type="checkbox"
          checked={selectAllDescription}
          onChange={() => handleSelectAllChange("description")}
          style={{ marginLeft: '5px' }}  // Adjust the margin as needed
        />
      </div>
    ),
    flex: 1,
    renderCell: (params) => {
      const name = params.row.description || params.row.name; 
      const start_time = params.row.startTime || params.row.start_time;
      const end_time = params.row.endTime || params.row.end_time;
      const isCopied = isItemCopied(name, start_time, end_time, 'descriptions');
      return (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.id]?.description || false}
            onChange={() => handleCheckboxChange(params.row.id, "description")}
            style={{ color: isCopied ? 'green' : 'blue' }}
          />
          {isCopied && <span title="Item copied to Description" style={{ color: 'green', marginLeft: '5px', position: 'relative', top: '-20px' }}>✓</span>}
        </div>
      );
    },
  }] : []),

  {
    field: 'highlight_tab',
    headerName: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>Highlight</span>
        <input
          type="checkbox"
          checked={selectAllHighlight}
          onChange={() => handleSelectAllChange("highlight")}
          style={{ marginLeft: '5px' }}  // Adjust the margin as needed
        />
      </div>
    ),
    flex: 1,
    renderCell: (params) => {
      const name = params.row.description || params.row.name; 
      const start_time = params.row.startTime || params.row.start_time;
      const end_time = params.row.endTime || params.row.end_time;
      const isCopied = isItemCopied(name, start_time, end_time, 'highlights');
      return (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.id]?.highlight || false}
            onChange={() => handleCheckboxChange(params.row.id, "highlight")}
            style={{ color: isCopied ? 'green' : 'blue' }}
          />
          {isCopied && <span title="Item copied to Highlight" style={{ color: 'green', marginLeft: '5px', position: 'relative', top: '-20px' }}>✓</span>}
        </div>
      );
    },
  },

  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 1,
    renderCell: () => (
      <div>AI Generated</div>
    ),
  },

  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        
        <span
          type="button"
          className="me-2 preview-Add-icon"
          onClick={() => handleViewCatalogue(params.row)
            
          }
        >
          <EyeIcon />
        </span>
        <CustomButton
          text="Apply"
          style={{ height: "30px", marginLeft: '10px', alignSelf: 'center'}}
          handleClick={() => {
            handleApplyButton(params.row);
          }}
          classes={"d-flex align-items-center"}
          type="btn-primary"
        />
      </div>
    ),
  },
];




  /* -------------------------------------------------- */

  const dataArray = [
      { 
      id: 4, 
      label: 'Catalogue', 
      view: (
        <>
          <CustomDataGrid
            getRowId={(row) => row.id}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            rows={filteredCatalogueData}
            columns={catalogueColumns}
          />
          {/* {totalCatalogueItem > 10 && (
            <Pagination
              totalItems={totalCatalogueItem}
              currentPage={currentPageCatalogue}
              itemsPerPage={itemsPerPageCatalogue}
              onPageChange={handlePageChangeCatalogue}
              onItemsPerPageChange={handleItemsPerPageChangeCatalogue}
            />
          )} */}
        </>
      ) 
    },

    {
      id: 0,
      label: "Description",
      view: (
        <>
          <CustomDataGrid
            getRowId={(row) => row.id}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            rows={DesctiptionData}
            columns={descriptioncolumns}
          />
          {totalDescriptionItem > 10 && (
            <Pagination
              totalItems={totalDescriptionItem}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </>
      ),
    },
    {
      id: 1,
      label: "Cart",
      view: (
        <>
          <CustomDataGrid
            getRowId={(row) => row.id}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            rows={CartData}
            columns={cartcolumns}
          />
          {totalCartItem > 10 && (
            <Pagination
              totalItems={totalCartItem}
              currentPage={currentPageCart}
              itemsPerPage={itemsPerPageCart}
              onPageChange={handlePageChangeCart}
              onItemsPerPageChange={handleItemsPerPageChangeCart}
            />
          )}
        </>
      ),
    },
    {
      id: 2,
      label: "HighLight",
      view: (
        <>
          <CustomDataGrid
            getRowId={(row) => row.id}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            rows={hightLightData}
            columns={highLightcolumns}
          />
          {TotalHighlightItem > 10 && (
            <Pagination
              totalItems={TotalHighlightItem}
              currentPage={currentPageHighlight}
              itemsPerPage={itemsPerPageHighlight}
              onPageChange={handlePageChangeHighlight}
              onItemsPerPageChange={handleItemsPerPageChangeHighlight}
            />
          )}
        </>
      ),
    },
    {
      id: 3,
      label: "Collection",
      view: (
        <>
          <CustomDataGrid
            getRowId={(row) => row.id}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            rows={collectionData}
            columns={collectionDataColumn}
          />
        </>
      ),
    },
  ];

  const handleSearchEmit = (searchValue) => {
    if (activeTab === "Description") setSearhValuedesc(searchValue);
    else if (activeTab === "Cart") setSearhValuecart(searchValue);
    else if (activeTab === "Catalogue") setSearchValueCatalogue(searchValue);
    else setSearhValuehigh(searchValue);
  };

  return (
    <PageCard>
      <div className="searchAddWrap d-flex justify-content-between">
        <Search
          placeholder="Search By Name"
          onSearchEmit={handleSearchEmit}
          searchedValue={
            activeTab === "Description"
              ? searchValuedesc
              : activeTab === "Cart"
                ? searchValuecart
                : activeTab === "Catalogue"
                  ? searchValueCatalogue                   
                  : searchValuehigh
                  
          }
        />
        <div className="d-flex gap-3">
          {(DesctiptionData?.length > 0 ||
            CartData?.length > 0 ||
            hightLightData?.length > 0 ||
            collectionData?.length > 0 ||  catalogueData?.length > 0) && (
            <>
              <CustomButton
                text="Publish"
                style={{ height: "40px" }}
                handleClick={() => {
                  handlePubishButton();
                }}
                classes={"d-flex align-items-center"}
                type="btn-primary"
                disabled={videoDataPreviw?.is_published}
              />
            </>
          )}
          {activeTab === "Catalogue" && (
            <CustomButton
              text="Apply to all"
              style={{ height: "40px" }}
              handleClick={() => {
                handleApplyButton();
              }}
              classes={"d-flex align-items-center"}
              type="btn-primary"
            />
          )}


          {(activeTab === "Description" || activeTab === "Cart" || activeTab === "HighLight") && (
  <>
    <input
      accept=".xlsx, .xls"
      style={{ display: 'none' }}
      id="import-excel"
      type="file"
      onChange={handleImportExcel}
    />
    <CustomButton
      text={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <ImportExportIcon style={{ marginRight: '8px' }} />
          Import an Excel
        </span>
      }
      style={{ height: "40px", backgroundColor: "#4CAF50", color: "white" }}
      classes={"d-flex align-items-center"}
      type="btn-secondary"
      handleClick={() => document.getElementById('import-excel').click()}
    />
    <CustomButton
      text={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <SaveAltIcon style={{ marginRight: '8px' }} />
          Export as an Excel
        </span>
      }
      style={{ height: "40px", backgroundColor: "#4CAF50", color: "white" }}
      handleClick={handleExportAsExcel}
      classes={"d-flex align-items-center"}
      type="btn-secondary"
    />
  </>
)}



          <CustomButton
            text="Preview"
            style={{ height: "40px" }}
            // icon={<Add fill="white" />}
            handleClick={() =>
              window.open(
                `${process.env.REACT_APP_VIDEO_BASE_URL + "/" + id}`,
                "_blank"
              )
            }
            classes={"d-flex align-items-center"}
            type="btn-primary"
          />
        </div>
      </div>
      <div className="requests-tab-div">
        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={dataArray}
        />
        <Box sx={{ width: "100%" }}>
          <Box>{_getComponent(activeTab)}</Box>
        </Box>
      </div>
    </PageCard>
  );
};

export default PreviewDashboard;