import React from 'react'
import Heading from '../../../atoms/Heading'
import Form from './Form'

const Verification = () => {
  return (
    <div>
    <Heading title='2-Step Verification'  description='Enter the verification code we sent you in your email. It will be valid for 5 minutes. If you need to resend a new code, please wait for 30 seconds.'/>
    <Form/>
</div>
  )
}

export default Verification