import React, { useState, useEffect } from "react";
import style from "./index.module.scss";

const LoadingIndicator = ({ duration }) => {
  const [displayMessage, setDisplayMessage] = useState("Hang on tight! We're fetching the data for you.");

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setDisplayMessage("We're almost there!");
    }, 5000);

    const timer2 = setTimeout(() => {
      setDisplayMessage("It’s taking longer than expected. Please stay with us while we finish up.");
    }, 10000);

    const timer3 = setTimeout(() => {
      setDisplayMessage("Almost there .....");
    }, 20000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  return (
    <div className={style.loadingWrapper}>
      <div className={style.spinner}></div>
      <p className={style.loadingMessage}>{displayMessage}</p>
    </div>
  );
};

export default LoadingIndicator;

