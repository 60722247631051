const EyeIcon = ({ className = "", width = "18", height = "19" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 21 20"
      fill="none"
    >
      <g clip-path="url(#clip0_1263_14544)">
        <path
          d="M19.4204 7.84858C18.126 5.74358 15.1817 2.21191 10.0143 2.21191C4.84681 2.21191 1.90255 5.74358 0.608183 7.84858C0.208045 8.49484 -0.00390625 9.23962 -0.00390625 9.99941C-0.00390625 10.7592 0.208045 11.504 0.608183 12.1502C1.90255 14.2552 4.84681 17.7869 10.0143 17.7869C15.1817 17.7869 18.126 14.2552 19.4204 12.1502C19.8205 11.504 20.0325 10.7592 20.0325 9.99941C20.0325 9.23962 19.8205 8.49484 19.4204 7.84858ZM17.9975 11.2777C16.8859 13.0827 14.3697 16.1202 10.0143 16.1202C5.65882 16.1202 3.14268 13.0827 2.03107 11.2777C1.79334 10.8936 1.66742 10.451 1.66742 9.99941C1.66742 9.54785 1.79334 9.10521 2.03107 8.72108C3.14268 6.91608 5.65882 3.87858 10.0143 3.87858C14.3697 3.87858 16.8859 6.91275 17.9975 8.72108C18.2352 9.10521 18.3611 9.54785 18.3611 9.99941C18.3611 10.451 18.2352 10.8936 17.9975 11.2777Z"
          fill="#4E5660"
          // className={className}
        />
        <path
          d="M10.0145 5.83301C9.18921 5.83301 8.38247 6.07738 7.69627 6.53522C7.01007 6.99306 6.47525 7.6438 6.15943 8.40516C5.8436 9.16652 5.76097 10.0043 5.92198 10.8126C6.08298 11.6208 6.48039 12.3632 7.06395 12.946C7.64752 13.5287 8.39102 13.9255 9.20044 14.0863C10.0099 14.2471 10.8489 14.1645 11.6113 13.8492C12.3738 13.5338 13.0255 12.9998 13.484 12.3146C13.9425 11.6293 14.1872 10.8238 14.1872 9.99967C14.1859 8.89501 13.7458 7.83597 12.9636 7.05486C12.1813 6.27374 11.1208 5.83433 10.0145 5.83301ZM10.0145 12.4997C9.51933 12.4997 9.03528 12.3531 8.62356 12.0783C8.21184 11.8036 7.89095 11.4132 7.70145 10.9564C7.51196 10.4996 7.46238 9.9969 7.55898 9.51195C7.65559 9.027 7.89403 8.58154 8.24417 8.23191C8.59431 7.88228 9.04041 7.64417 9.52606 7.54771C10.0117 7.45125 10.5151 7.50076 10.9726 7.68998C11.4301 7.87919 11.8211 8.19963 12.0962 8.61075C12.3713 9.02187 12.5181 9.50522 12.5181 9.99967C12.5181 10.6627 12.2543 11.2986 11.7848 11.7674C11.3153 12.2363 10.6785 12.4997 10.0145 12.4997Z"
          fill="#4E5660"
          // className={className}
        />
      </g>
      <defs>
        <clipPath id="clip0_1263_14544">
          <rect width="20.0289" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EyeIcon;
