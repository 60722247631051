import React from "react";
import { Outlet } from "react-router-dom";
// import Logo from "../../../assets/brandLogo/LoginImage.png";

const AuthLayout = ({ children }) => {
  return (
    <div className="auth-screen">
      <div className="auth-image">
        {/* <img src={Logo} className="logo-image" /> */}
      </div>
      <div className="auth-content">
        <div className="auth-child">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
