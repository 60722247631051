import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import "./videoupload.css";
import UploadIcon from "../../../icons/uploadIcon";
import VideoIcon from "../../../icons/videoIcon";
import CloseBtnIcom from "../../../icons/CloseBtnIcom";
import toast from "react-hot-toast";
import {
  confirmationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";

function VideoUpload({
  videoData,
  setVideoData,
  videoDurations,
  setVideoDurations,
}) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedVideoURL, setUploadedVideoURL] = useState("");
  const videodataRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (videoData) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedVideoURL(reader.result);
      };
      reader.readAsDataURL(videoData);
    }
  }, [videoData]);

  const getDurationFromFile = async (file) => {
    try {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      const loadedMetadataPromise = new Promise((resolve) => {
        video.onloadedmetadata = () => {
          resolve();
        };
      });

      video.load(); // Start loading the video metadata

      await loadedMetadataPromise; // Wait for the metadata to be loaded

      const totalSeconds = Math.floor(video.duration);
      const hours = Math.floor(totalSeconds / 3600); // Calculate hours
      const remainingSeconds = totalSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60); // Calculate minutes
      const seconds = remainingSeconds % 60; // Calculate seconds

      // Format duration as hh:mm:ss
      const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedDuration; // Return the formatted duration in hh:mm:ss format
    } catch (error) {
      console.error("Error loading video duration:", error);
      return null; // Return null if there's an error
    }
  };

  const fetchVideoDurationsFromFiles = async (files) => {
    setVideoDurations(null);
    const duration = await getDurationFromFile(files);
    setVideoDurations(duration);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    // const reader = new FileReader();
    // console.log("----->", file);
    // reader.onload = (event) => {
    //   const url = URL.createObjectURL(file);
    //   videodataRef.current.src = url;

    //   // console.log("=====>", (videodataRef.current.currentTime = 1));

    //   videodataRef.current.onloadedmetadata = () => {
    //     captureThumbnail();
    //   };
    // };
    // reader.readAsDataURL(file);
    if (file && file.type.startsWith("video/")) {
      if (file.type === "video/mp4" || file.type === "video/mp3") {
        // Check for video resolution
        const video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = async function () {
          // if (
          //   (video.videoWidth !== 1920 || video.videoHeight !== 1080) &&
          //   (video.videoWidth !== 1280 || video.videoHeight !== 720)
          // ) {
          //   toast.error("Video resolution should be 720p or 1080p", {
          //     duration: 1000,
          //     style: errorStyles,
          //   });
          // console.log("Video resolution should be 1080p Resolution.");
          // } else {
          setVideoData(file);
          console.log("videoData:", videoData); // Check the value of videoData
          if (file) {
            console.log("Inside if (videoData) block"); // Debug statement
            try {
              const duration = await fetchVideoDurationsFromFiles(file);
            } catch (error) {
              console.error("Error fetching video duration:", error);
            }
            // }
            const fileSize = file.size;
            const chunkSize = 1024 * 1024;
            let bytesUploaded = 0;

            const uploadInterval = setInterval(() => {
              bytesUploaded += chunkSize;
              const progress = Math.min((bytesUploaded / fileSize) * 100, 100);
              setUploadProgress(progress);
              if (bytesUploaded >= fileSize) {
                clearInterval(uploadInterval);
                console.log("Upload successful!");
              }
            }, 500);
          }
        };
        video.src = URL.createObjectURL(file);
      } else {
        toast.error("Only MP4 and MP3 video files are allowed", {
          duration: 1000,
          style: errorStyles,
        });
        console.log("Only MP4 and MP3 video files are allowed.");
      }
    } else {
      toast.error("Please select a valid video file.", {
        duration: 1000,
        style: errorStyles,
      });
      console.log("Please select a valid video file.");
    }
  };
  // const captureThumbnail = () => {
  //   const video = videodataRef.current;
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   video.currentTime = 10;

  //   canvas.width = video.videoWidth;
  //   canvas.height = video.videoHeight;

  //   video.addEventListener("seeked", () => {
  //     // Draw the first frame of the video onto the canvas
  //     ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  //     const thumbnailUrl = canvas.toDataURL("image/jpeg");
  //     const thumbnailFile = dataURLtoFile(thumbnailUrl, "thumbnail.jpg");
  //     setVideoThumbnail(thumbnailFile);
  //     console.log("thumbnailUrl", thumbnailUrl); // You can use this URL to display the thumbnail
  //   });

  // Convert the canvas image to a data URL
  // };
  // function dataURLtoFile(dataURL, filename) {
  //   const arr = dataURL.split(",");
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  return (
    <div className="upload-container">
      <label>Add Video</label>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <div className="upload-container-content">
          <div>
            <UploadIcon />
          </div>
          <div className="mt-2">
            Drag & Drop or <a className="video-file-anchor">Choose file</a> to
            upload video
          </div>
          {/* <div>Constraints:Mp4 and 1080p,720p videos are allowed</div> */}
        </div>
      </div>
      <video ref={videodataRef} controls style={{ display: "none" }}></video>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {/* <div className="preview-or-progress">
        {!(uploadProgress === 100 && uploadedVideoURL) &&
          <div className="thumbnail">
            {videoData && (
              <>
                <p>Uploading video: {videoData.name}</p>
                <progress value={uploadProgress} max="100"></progress>
                <p>{uploadProgress.toFixed(2)}% uploaded</p>
              </>
            )}
          </div>
        }
      </div> */}
      {videoData && (
        <div
          className="video-upload-progress"
          style={uploadProgress === 100 ? { paddingBottom: "15px" } : {}}
        >
          <div className="video-upload-progress-content">
            <div className="d-flex gap-4 align-items-center">
              <div>
                <VideoIcon className="video-icon-container" />
              </div>
              <div>
                <div>{videoData?.name}</div>
                <div>{(videoData?.size / (1024 * 1024)).toFixed(2)} Mb</div>
              </div>
            </div>
            <div className="d-flex align-items-center flex-column">
              <div onClick={() => setVideoData(null)}>
                <CloseBtnIcom />
              </div>
              <div>
                {uploadProgress === 100
                  ? "Uploaded"
                  : `${uploadProgress.toFixed()}%`}
              </div>
            </div>
          </div>
          {uploadProgress !== 100 && (
            <progress value={uploadProgress} max="100"></progress>
          )}
        </div>
      )}
    </div>
  );
}

export default VideoUpload;
