import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomDataGrid from "../../atoms/CustomDataGrid";
import PageCard from "../../atoms/PageCard";
import PageHeader from "../../atoms/PageHeader";
import Search from "../../atoms/Search";
import Pagination from "../../atoms/Pagination";
import style from "./index.module.scss";
import * as Yup from "yup";
import CustomButton from "../../atoms/CustomButton";
import Add from "../../icons/add";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import EditIcon from "../../icons/editIcon";
import EyeIcon from "../../icons/eyeIcon";
import Modal from "../../atoms/Modals/Modal";
import VideoUpload from "./AddVideos/VideoUpload";
import {
  deleteData,
  getData,
  patchFormData,
  postFormData,
} from "../../../services";
import DeleteIcon from "../../icons/deleteIcon";
import moment, { duration } from "moment";
import DeleteModal from "../../atoms/Modals/DeleteModal";

import toast from "react-hot-toast";
import { confirmationStyles, errorStyles } from "../../../assets/styles/toast";
import InputLayout from "../../atoms/InputLayout";
import NoContentCard from "../../atoms/NoContentCard/index.tsx";
import ImageSelector from "../../atoms/ImageSelector/index.jsx";
import { replaceSpacesInImageUrl } from "../../../utils/Helpers/replaceSpaceInImage.jsx";
import FileEditIcon from "../../icons/filrEditIcon.jsx";
import { useSelector } from "react-redux";
import { RoleData } from "../../../utils/staticData/roleData.js";

const Videos = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [modalState, setModalState] = useState({
    isActive: false,
    title: "Add Video",
  });
  const [editmodalState, setEditModalState] = useState({
    isActive: false,
    title: "Edit Video",
    editData: [],
    thumbnailFile: null,
  });

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const [cookies] = useCookies(["t", "bid", "auid"]);
  const [videoData, setVideoData] = useState(null);
  const [AllvideoData, setAllVideoData] = useState([]);
  const [totalVideoslen, SetTotalVideosLen] = useState();
  const [videoDataById, setVideoDataById] = useState({
    isActive: false,
    data: [],
  });
  const [isVideoCompleted, setIsVideoCompleted] = useState(false);
  const [videoName, setVideoName] = useState("");
  const [searchValue, setSearchValue] = useState();
  const [deleteModal, setdeleteModal] = useState({
    isModalOpen: false,
    data: {},
  });
  const [videoDurations, setVideoDurations] = useState();
  const [videoThumbnail, setVideoThumbnail] = useState();
  const [videoDescription, setVideoDescription] = useState("");
  const [removePhoto, setRemovePhoto] = useState(false);
  const [videoPurposes, setVideoPurposes] = useState({});

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Video name is required"),
    // Add more validations for other fields if needed
  });

  const getDurationFromFile = async (file) => {
    try {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      const loadedMetadataPromise = new Promise((resolve) => {
        video.onloadedmetadata = () => {
          resolve();
        };
      });

      video.load(); // Start loading the video metadata

      await loadedMetadataPromise; // Wait for the metadata to be loaded

      const totalSeconds = Math.floor(video.duration);
      const hours = Math.floor(totalSeconds / 3600); // Calculate hours
      const remainingSeconds = totalSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60); // Calculate minutes
      const seconds = remainingSeconds % 60; // Calculate seconds

      // Format duration as hh:mm:ss
      const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedDuration; // Return the formatted duration in hh:mm:ss format
    } catch (error) {
      console.error("Error loading video duration:", error);
      return null; // Return null if there's an error
    }
  };

  const fetchVideoDurationsFromFiles = async (files) => {
    const duration = await getDurationFromFile(files);
    console.log("primary  :", duration);
    setVideoDurations(duration);
  };

  const handleVideoSubmit = async (file) => {
    setAllVideoData((prevAllVideoData) => [
      {
        id: "12345",
        video_name: videoData?.name,
      },
      ...prevAllVideoData,
    ]);
    setModalState({
      isActive: false,
    });
    try {
      setIsVideoCompleted(false);
      const res = await postFormData(
        "Video/addVideo",
        {
          name: videoName,
          duration: videoDurations,         
          business_id: cookies.bid,
          video: videoData,
          user_id: cookies.auid,
          thumbnail: videoThumbnail,
          description: videoDescription,
          purpose: videoPurposes["12345"],
          
        },
        {},
        cookies.t,
        
      );
      setIsVideoCompleted(true);
      getVideoDetail(cookies.t);
      toast.success("The Video Was Processed", {
        duration: 5000,
        style: confirmationStyles,
      });
      console.log("Response from server:", res);
      console.log(res);
    } catch (error) {
      console.error(error);
      toast.error("The Video Processing was failed", {
        duration: 5000,
        style: errorStyles,
      });
    } finally {
      // Remove the first item from the array after getting the response
      setAllVideoData((prevAllVideoData) => {
        const newData = [...prevAllVideoData];
        newData.shift();
        return newData;
      });
    }
  };

  const getVideoDetail = async (token) => {
    try {
      const response = await getData({
        endpoint: "Video/getAllVideos",
        params: {
          business_id: cookies.bid,
          page: currentPage,
          page_limit: itemsPerPage,
          search_query: searchValue,
        },
        token: token,
      });
      setAllVideoData(response?.data);
      SetTotalVideosLen(response?.total_record);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getVideoDetail(cookies.t);
    console.log(AllvideoData);
  }, [cookies.t, searchValue, currentPage, itemsPerPage]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = React.memo(() => {
    return <>{<NoContentCard title="No Items Added Yet!" type="Search" />}</>;
  });

  const getVideoDetailById = async ({ id, token }) => {
    console.log("id  :", id);
    try {
      const response = await getData({
        endpoint: "Video/getVideoDetailsById",
        params: {
          video_id: id,
        },
        token: token,
      });
      return response; // Return the data from the function
    } catch (error) {
      console.log(error);
      throw error; // Re-throw the error to handle it outside
    }
  };

  const handleEdit = async (data) => {
    navigate(`/dashboard/videos/videoplayer/${data?.id}`);
  };

  const handleView = (data) => {
    window.open(
      `${process.env.REACT_APP_VIDEO_BASE_URL + "/" + data?.id}`,
      "_blank"
    );
  };

  const handleFileEdit = (data) => {
    setEditModalState((prevState) => ({
      ...prevState,
      isActive: true,
      editData: data,
      thumbnailFile: data?.thumbnail,
    }));
    console.log("data  :", data);
  };

  const handleDelete = async (data) => {
    if (data) {
      try {
        const response = await deleteData({
          endpoint: "Video/deleteVideo",
          params: { video_id: data?.id },
          token: cookies.t,
        });
        if (response) {
          setdeleteModal(() => ({
            isModalOpen: false,
            data: {},
          }));
        }
        getVideoDetail(cookies.t);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleVideoEdit = async () => {
    setEditModalState({ ...editmodalState, isActive: false });
    console.log("thumbnail:", editmodalState?.thumbnailFile);
    console.log("Purpose:", editmodalState?.editData?.purpose, "Video Purposes:", videoPurposes[editmodalState?.editData?.id]);
    try {
      setIsVideoCompleted(false);
      const res = await patchFormData(
        "Video/editVideo",
        {
          video_id: editmodalState?.editData?.id,
          thumbnail: editmodalState?.thumbnailFile,
        },
        {
          name: editmodalState?.editData?.name,
          description: editmodalState?.editData?.description,
          purpose: videoPurposes[editmodalState?.editData?.id] || editmodalState?.editData?.purpose,
          remove_photo: removePhoto,
        },

        cookies.t
      );
      setIsVideoCompleted(true);
      setEditModalState({
        ...editmodalState,
        isActive: false,
        editData: [],
        thumbnailFile: null,
      });
      setVideoDescription("");
      getVideoDetail(cookies.t);
      toast.success("The Video Details was updated", {
        duration: 2000,
        style: confirmationStyles,
      });
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  const getDuration = async (videoUrl) => {
    try {
      const video = document.createElement("video");
      video.src = videoUrl;

      const loadedMetadataPromise = new Promise((resolve) => {
        video.onloadedmetadata = () => {
          resolve();
        };
      });

      video.load(); // Start loading the video metadata

      await loadedMetadataPromise; // Wait for the metadata to be loaded

      const totalSeconds = Math.floor(video.duration);
      const hours = Math.floor(totalSeconds / 3600); // Calculate hours
      const remainingSeconds = totalSeconds % 3600;
      const minutes = Math.floor(remainingSeconds / 60); // Calculate minutes
      const seconds = remainingSeconds % 60; // Calculate seconds

      // Format duration as hh:mm:ss
      const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return formattedDuration; // Return the formatted duration in hh:mm:ss format
    } catch (error) {
      console.error("Error loading video duration:", error);
      return null; // Return null if there's an error
    }
  };

  const fetchVideoDurations = async (videoLinks) => {
    const duration = await getDuration(videoLinks);
    setVideoDurations(duration); // Update the state with the durations
  };

  function formatTime(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const handlePurposeChange = (videoId, purpose) => {
      console.log(`Dropdown selection changed for videoId: ${videoId}, new purpose: ${purpose}`);
      setVideoPurposes((prevPurposes) => ({
          ...prevPurposes,
          [videoId]: purpose,
      }));
  };


  const columns = [
    {
      field: "name",
      headerName: "Video Name",
      flex: 1.5,
      renderCell: (params) => {
        return params.row.id === "12345" ? (
          <div className={style.loader_video_container}>{videoName}</div>
        ) : (
          <div>{params?.row?.name}</div>
        );
      },
    },
    {
      field: "description",
      headerName: "Video Description",
      flex: 1.5,
    },
    {
      field: "duration",
      headerName: "Video Duration",
      flex: 1,
      renderCell: (params) => {
        return <div className="ms-4">{params?.row?.duration}</div>;
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1,
      renderCell: (params) => {
        return <div>{params?.row?.purpose}</div>;
      },
    },
    {
      field: "videodate",
      headerName: "Created At",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.created_date
              ? moment(params?.row?.created_date).format("Do MMM YY, hh:mm a")
              : ""}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.id === "12345" ? (
              <div className={style.loader_video_container}>Processing...</div>
            ) : (
              <>
                {userInfo?.role !== RoleData.VIEWER && (
                  <span
                    type="button"
                    className="me-2"
                    onClick={() => handleEdit(params.row)}
                  >
                    <EditIcon />
                  </span>
                )}
                <span
                  type="button"
                  className="me-2"
                  onClick={() => handleView(params.row)}
                >
                  <EyeIcon />
                </span>
                {userInfo?.role !== RoleData.VIEWER && (
                  <span
                    type="button"
                    className="me-2"
                    onClick={() => {
                      setdeleteModal(() => ({
                        isModalOpen: true,
                        data: params?.row,
                      }));
                      // handleDelete(params.row);
                    }}
                  >
                    <DeleteIcon />
                  </span>
                )}
                {userInfo?.role !== RoleData.VIEWER && (
                  <span
                    style={{ cursor: "pointer" }}
                    className={style.video_fie_edit}
                    onClick={() => handleFileEdit(params.row)}
                  >
                    <FileEditIcon />
                  </span>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];
  const handleSearchVideo = (searchedValueLib) => {
    setSearchValue(searchedValueLib);
  };

  // console.log("alll  :", AllvideoData.length);
  const handleFileChange = (event) => {
    const files = event.target.files;
    console.log(files);
  };

  return (
    <>
      <video id="videoPlayer" controls style={{ display: "none" }}></video>
      <PageHeader title="Videos" />
      <PageCard>
        <div className={style.searchAddWrap}>
          <Search onSearchEmit={handleSearchVideo} />
          {userInfo?.role !== RoleData.VIEWER && (
            <CustomButton
              text="Add New"
              icon={<Add fill="white" />}
              classes={"d-flex align-items-center"}
              type="btn-primary"
              handleClick={() => {
                setVideoName("");
                setVideoDescription("");
                setVideoThumbnail(null);
                setVideoData(null);
                setModalState({ isActive: true });
              }}
            />
          )}
        </div>
        <CustomDataGrid
          getRowId={(row) => row.id}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          rows={AllvideoData}
          columns={columns}
        />
        {totalVideoslen > 10 && (
          <Pagination
            totalItems={totalVideoslen}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </PageCard>
      <Modal
        isActive={
          videoDataById?.isActive
            ? videoDataById?.isActive
            : modalState.isActive
        }
        width="642px"
        title={videoDataById?.isActive ? "Edit Video" : "Add Video"}
        onClose={() => {
          setModalState({ isActive: false });
          setVideoDataById({ isActive: false });
        }}
      >
        <InputLayout>
          <label htmlFor="url">Video Name</label>
          <input
            type="text"
            placeholder="Enter Video Name"
            className="mb-3"
            onChange={(e) =>
              !videoDataById?.isActive
                ? setVideoName(e.target.value)
                : setVideoDataById((prevData) => ({
                    ...prevData,
                    data: {
                      ...prevData.data,
                      name: e.target.value,
                    },
                  }))
            }
            value={
              videoDataById?.isActive
                ? videoDataById?.data?.name || ""
                : videoName
            }
          />
        </InputLayout>

        <InputLayout>
          <label htmlFor="url">Video Description</label>
          <input
            type="text"
            placeholder="Enter Video Description"
            className="mb-3"
            onChange={(e) =>
              !videoDataById?.isActive
                ? setVideoDescription(e.target.value)
                : setVideoDataById((prevData) => ({
                    ...prevData,
                    data: {
                      ...prevData.data,
                      name: e.target.value,
                    },
                  }))
            }
            value={
              videoDataById?.isActive
                ? videoDataById?.data?.name || ""
                : videoDescription
            }
          />
        </InputLayout>


        <InputLayout>
        <label htmlFor="purpose">
          Select Purpose
        </label>
        <select
          value={videoPurposes["12345"] || ""}
          onChange={(e) =>
            handlePurposeChange("12345",
              e.target.value)}
          className="mb-3"
        >
          <option value="">Select</option>
          <option
          value="Entertainment">Entertainment</option>
          <option
          value="Advertisement">Advertisement</option>
        </select>
        </InputLayout>




        <InputLayout>
          <label className="mt-3">Thumbnail</label>
          <ImageSelector
            handleFile={(file) => {
              console.log("option", file);
              setVideoThumbnail(file);
            }}
            selectedImage={replaceSpacesInImageUrl(
              videoThumbnail?.profile_photo
            )}
            maxFileSize={0.3}
            // selectedImages={values?.profile_photo}
            onRemove={() => setVideoThumbnail("remove_profile_photo", true)}
          />
        </InputLayout>
        {!videoDataById?.isActive && (
          <VideoUpload
            videoData={videoData}
            setVideoData={setVideoData}
            videoDurations={videoDurations}
            setVideoDurations={setVideoDurations}
          />
        )}
        <div className={`${style.video_footer_container}`}>
          {videoDataById.isActive ? (
            <button
              type="submit"
              className={`btn btn-primary px-4 `}
              onClick={handleVideoEdit}
            >
              Edit Video
            </button>
          ) : (
            <button
              type="submit"
              className={`btn btn-primary px-4 `}
              onClick={handleVideoSubmit}
              disabled={!videoData || !videoName}
            >
              Add Video
            </button>
          )}
        </div>
      </Modal>
      <Modal
        isActive={editmodalState.isActive}
        title={editmodalState.title}
        onClose={() => {
          setEditModalState({
            ...editmodalState,
            isActive: false,
            editData: [],
            thumbnailFile: null,
          });
        }}
      >
        <InputLayout>
          <label htmlFor="url">Video Name</label>
          <input
            type="text"
            placeholder="Enter Video Name"
            className="mb-3"
            onChange={(e) =>
              setEditModalState((prevData) => ({
                ...prevData,
                editData: {
                  ...prevData.editData,
                  name: e.target.value,
                },
              }))
            }
            value={editmodalState.editData.name}
          />
        </InputLayout>
        <InputLayout>
          <label htmlFor="url">Video Description</label>
          <input
            type="text"
            placeholder="Enter Video Name"
            className="mb-3"
            onChange={(e) =>
              setEditModalState((prevData) => ({
                ...prevData,
                editData: {
                  ...prevData.editData,
                  description: e.target.value,
                },
              }))
            }
            value={editmodalState.editData.description}
          />
        </InputLayout>

        <InputLayout>
          <label htmlFor="purpose">Select Purpose</label>
          <select
            value={editmodalState.editData.purpose || ""}
            onChange={(e) =>
              
              setEditModalState((prevData) => ({
                ...prevData,
                editData: {
                  ...prevData.editData,
                  purpose: e.target.value,
                },
              }))
            }
            className="mb-3"
          >
            <option value="">Select</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Advertisement">Advertisement</option>
          </select>
        </InputLayout>


        <InputLayout>
          <label className="mt-3">Thumbnail</label>
          <ImageSelector
            handleFile={(file) => {
              console.log("------>", file);
              setEditModalState((prevData) => ({
                ...prevData,
                thumbnailFile: file,
              }));
              setRemovePhoto(false);
            }}
            selectedImage={replaceSpacesInImageUrl(
              editmodalState?.editData.thumbnail
            )}
            onRemove={() => {
              setEditModalState((prevData) => ({
                ...prevData,
                thumbnailFile: null,
              }));
              setRemovePhoto(true);
            }}
            maxFileSize={0.13}
          />
        </InputLayout>
        <button
          type="submit"
          className={`btn btn-primary px-4 mb-3`}
          onClick={handleVideoEdit}
          style={{ margin: "auto 0 auto auto" }}
        >
          Update Details
        </button>
      </Modal>
      <DeleteModal
        isActive={deleteModal.isModalOpen}
        onClick={() => {
          handleDelete(deleteModal.data);
        }}
        onClose={() => {
          setdeleteModal(() => ({
            isModalOpen: false,
            data: {},
          }));
        }}
      />
    </>
  );
};
export default Videos;
