import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
console.log("Base URL being used:", baseURL);

export const instance = axios.create({
  baseURL: `${baseURL}`, // Your base URL
  headers: {
    "Content-Type": "application/json",
    "User-Role": "Customer",
  },
});

export const postData = async ({ endpoint, data, params, token }) => {
  console.log("Endpoint:", endpoint);
  console.log("Data:", data);
  // console.log("Params:", params);
  console.log("Token:", token);
  try {
    const response = await instance.post(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Set isLoading to false when the API call is complete
    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    console.error("Response status:", error.response.status);
    console.error("Response headers:", error.response.headers);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

export const postFormData = async (endpoint, data, params, token) => {

  console.log("Endpoint:", endpoint);
  console.log("Data:", data);
  // console.log("Params:", params);
  console.log("Token:", token);
  try {
    console.log("POST Request Details:", { endpoint, data, params, token });
    const response = await instance.post(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("POST Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getData = async ({ endpoint, params, token, secretkey }) => {
  try {
    const response = await instance.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Secret-Key": secretkey && secretkey,
      },
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};

export const patchData = async ({ endpoint, data, params, token }) => {
  try {
    const response = await instance.patch(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};
export const patchFormData = async (endpoint, data, params, token) => {
  try {
    console.log("POST Request Details:", { endpoint, data, params, token });
    const response = await instance.patch(endpoint, data, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("POST Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const deleteData = async ({ endpoint, params, token }) => {
  try {
    const response = await instance.delete(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error deleting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};
