import React, { useEffect, useRef, useState } from "react";
import "./ExcelUpload.css";
import CloseBtnIcom from "../../../icons/CloseBtnIcom";
import VideoIcon from "../../../icons/videoIcon";

const ExcelUpload = ({excelFile,setExcelFile,excelFileRef}) => {
  const [typeError, setTypeError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        setExcelFile(selectedFile);
        setSubmitDisabled(false);
      } else {
        setTypeError("Please select only Excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      setSubmitDisabled(true);
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
          console.log("File uploaded successfully:", excelFile);
          // setUploadComplete(true);
          setSubmitDisabled(false);
          setUploadProgress(0);
        }
      }, 500);
    } else {
      console.log("Please select a file before uploading");
    }
  };

  if (uploadComplete) {
  }
  return (
    <div className="wrapper">
      <form className="form-group custom-form">
        <label>Product List (.xls,.xlsx,.csv)</label>
        <input
          type="file"
          className="form-control"
          required
          accept=".xls, .xlsx, .csv"
          onChange={handleFile}
          ref={excelFileRef}
        />
        {typeError && (
          <div className="alert alert-danger" role="alert">
            {typeError}
          </div>
        )}
      </form>
      {/* {uploadProgress > 0 && !uploadComplete && (
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${uploadProgress}%` }}
            aria-valuenow={uploadProgress}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {uploadProgress}%
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ExcelUpload;
