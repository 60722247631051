import routes from "./routes";
import { useRoutes } from "react-router-dom";
import {Toaster} from 'react-hot-toast';

function App() {
  const content = useRoutes(routes);
  return <>
   <Toaster
        toastOptions={{
          className: "",
          style: {
            color: "#ffffff",
            width: "max-content",
          },
        }}
      />
  {content}
  </>;
}

export default App;
