import { useCookies } from "react-cookie";
import CustomButton from "../CustomButton";
import CloseBtnIcom from "../../icons/CloseBtnIcom";
import { useNavigate } from "react-router-dom";
const CloseButton = ({ onClose, noCloseIcon = false }) => {
  return (
    !noCloseIcon && (
      <>
        <div className="close-btn" onClick={onClose} style={{ border: "none" }}>
          <i className="d-flex" style={{ fontSize: "18px", width: "18px" }}>
            <CloseBtnIcom />
          </i>
        </div>
      </>
    )
  );
};

const LogoutModal = ({
  width = "400px",
  onClose,
  isActive,
  title,
  content,
  children,
  noCloseIcon,
  onConfirm,
}) => {
  const [, , removeCookies] = useCookies(["t", "un", "email", "uid", "role"]);
  const cookieNames = ["t", "un", "email", "uid", "role"];
  const navigate = useNavigate();
  const handleLogout = () => {
    cookieNames.forEach((cookieName) => {
      removeCookies(cookieName, { path: "/" });
    });
    navigate("/");
  };
  const styles = {
    width: width,
  };
  return (
    <div className={`modal ${isActive ? "active" : ""}`}>
      <div className="modal-content" style={styles}>
        <div className="modal-body py-4">
          <div className="d-flex justify-content-between">
            <p className="modal-heading mb-0 mt-2">
              {title ? title : "Logout"}
            </p>
            <CloseButton onClose={onClose} noCloseIcon={noCloseIcon} />
          </div>
          <p
            className="modal-description"
            style={content ? { marginBottom: "0" } : {}}
          >
            {content ? content : "Are you sure do you want to Logout."}
          </p>
          {children ? (
            children
          ) : (
            <div className="d-flex w-100 mt-3">
              <CustomButton
                text="No"
                handleClick={onClose}
                style={{ flex: "1 0 45%" }}
              />
              {/* Adjust the style for the "Confirm" button */}
              <button
                className="btn btn-primary mt-0"
                onClick={onConfirm ? onConfirm : handleLogout}
                style={{ flex: "1 0 45%", marginLeft: "10px" }}
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
