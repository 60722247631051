import { Field, FieldArray, Formik } from "formik";
import ImageSelector from "../../../atoms/ImageSelector";
import InputLayout from "../../../atoms/InputLayout";
import { useEffect, useState } from "react";
import { countrybyCurrency } from "../../../../utils/Helpers/countryByCurrency";
import SelectDropdown from "../../../atoms/Dropdown";
import styles from "./index.module.scss";
import { patchData, postData, postFormData } from "../../../../services";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import Error from "../../../atoms/Error";
import { replaceSpacesInImageUrl } from "../../../../utils/Helpers/replaceSpaceInImage";
import Add from "../../../icons/add";
import CustomTimePicker from "../../../atoms/TimePicker";
import toast from "react-hot-toast";
import { confirmationStyles } from "../../../../assets/styles/toast";
const AddCollection = ({
  video_id,
  hoverItem,
  playerTime,
  videoLink,
  setModalState,
  getCollectionDetails,
  getVideopreviewDataById,
  setChooseCoordinates,
  videoEditTable,
  collectionData,
  setIsLoading,
  isLoading,
}) => {
  const [maxTime, setMaxTime] = useState(false); // Initial maximum time set to 23:59
  const [duration, setDuration] = useState();
  const [cookies] = useCookies(["t", "auid"]);

  const CollectionDataPlayerTime = collectionData?.map((item) => {
    return {
      start_time: item?.start_date,
      end_time: item?.end_date,
    };
  });

  useEffect(() => {
    const getDuration = async (videoUrl) => {
      try {
        const video = document.createElement("video");
        video.src = videoUrl;

        const loadedMetadataPromise = new Promise((resolve) => {
          video.onloadedmetadata = () => {
            resolve();
          };
        });

        video.load(); // Start loading the video metadata

        await loadedMetadataPromise; // Wait for the metadata to be loaded

        const totalSeconds = Math.floor(video.duration);
        const hours = Math.floor(totalSeconds / 3600); // Calculate hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
        const seconds = totalSeconds % 60; // Calculate seconds

        const formattedDuration = `${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;

        return formattedDuration; // Return the formatted duration in HH:MM:SS format
      } catch (error) {
        console.error("Error loading video duration:", error);
        return null; // Return null if there's an error
      }
    };

    const calculateDuration = async () => {
      try {
        const duration = await getDuration(videoLink);
        setDuration(duration);
      } catch (error) {
        console.error("Error calculating duration:", error);
        setDuration(null); // Handle error by setting duration to null
      }
    };

    calculateDuration(); // Call the function immediately when the component mounts
  }, [videoLink]); // Include videoLink in the dependency array to recalculate duration when it changes

  const timeStringToSeconds = (timeString) => {
    if (timeString) {
      const [timePart, decisecondPart] = timeString.split('.');
      const [hours, minutes, seconds] = timePart.split(":").map(Number);
      const deciseconds = decisecondPart ? parseInt(decisecondPart, 10) / 10 : 0;
      return hours * 3600 + minutes * 60 + seconds + deciseconds;
    }
  };


  const validateStartTime = (startTime, timeIntervals) => {
    console.log(
      "selected Time: ",
      timeStringToSeconds(videoEditTable.start_date)
    );
    if (
      videoEditTable &&
      timeStringToSeconds(startTime) >=
        timeStringToSeconds(videoEditTable.start_date) &&
      timeStringToSeconds(startTime) <=
        timeStringToSeconds(videoEditTable.end_date)
    )
      return true;
    const startSeconds = timeStringToSeconds(startTime);
    return !timeIntervals.some((interval) => {
      const intervalStart = timeStringToSeconds(interval.start_time);
      const intervalEnd = timeStringToSeconds(interval.end_time);
      return startSeconds >= intervalStart && startSeconds <= intervalEnd;
    });
  };

  const validationSchema = () =>
    Yup.object().shape({
      collectionStartTime: Yup.string()
        .required("Start time is required")
        .test(
          "is-not-overlapping",
          "Start time overlaps with existing intervals",
          function (value) {
            return validateStartTime(value, CollectionDataPlayerTime);
          }
        ),
      collectionEndTime: Yup.string()
        .required("End time is required")
        .matches(
          /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9](\.\d{1})?$/,
          "Invalid time format. Please use HH:MM:SS.d format"
        )
        .test(
          "is-greater",
          "End time must be greater than start time",
          function (value) {
            const { collectionStartTime } = this.parent;
            if (!collectionStartTime || !value) return true;
            return (
              new Date(`2000-01-01T${value}`) >
              new Date(`2000-01-01T${collectionStartTime}`)
            );
          }
        ),

    });

  function formatTime(seconds) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.floor(seconds % 60);

    // Add leading zeros if necessary
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainingSeconds =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
    console.log(hours + ":" + minutes + ":" + remainingSeconds);
    if (seconds === 0) return "00:00:00";
    return hours + ":" + minutes + ":" + remainingSeconds;
  }

  const handleEndTimeChange = (time) => {
    function calculateTotalSeconds(timeString) {
      var parts = timeString?.split(":");
      console.log("parts", parts);

      // Convert hours, minutes, and seconds into integers
      var hours = parseInt(parts?.[0], 10);
      var minutes = parseInt(parts?.[1], 10);
      var seconds = parseInt(parts?.[2], 10);

      // Calculate total seconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds;

      return totalSeconds;
    }
    const Time = calculateTotalSeconds(time);
    const durationTime = calculateTotalSeconds(duration);
    if (Time > durationTime) setMaxTime(true);
    else setMaxTime(false);
  };

  const collectionInitialValues = {
    collectionName: videoEditTable?.name || "",
    product_cta: videoEditTable?.product_cta || "",
    collectionStartTime:
      playerTime !== ""
        ? formatTime(playerTime)
        : videoEditTable?.start_date || "",
    collectionEndTime: videoEditTable?.end_date || "",
    collectionPositionX: hoverItem?.positionx,
    collectionPositionY: hoverItem?.positiony,
    id: videoEditTable?.id || null,

    collection: (videoEditTable?.collection_data || [null]).map(
      (collectionEditData) => ({
        collectionId: collectionEditData?.id || "",
        collectionImages: collectionEditData?.photo || null,
        collectionProductName: collectionEditData?.name || "",
        collectionDescription: collectionEditData?.description || "",
        collectionPrice: collectionEditData?.price || "",
        collectionLink: collectionEditData?.link || "",
        collectionCurrency: collectionEditData?.currency || "",
      })
    ),
  };

  const collectionHandleSubmit = async (values) => {
    try {
      setIsLoading(true);
      if (videoEditTable?.id) {
        const res = await patchData({
          endpoint: "VideoProduct/editProductCollection",
          params: {},
          data: {
            id: values?.id,
            name: values?.collectionName,
            product_cta: values?.product_cta,
            start_time: values?.collectionStartTime,
            end_time: values?.collectionEndTime,
            x_coordinate: values?.collectionPositionX,
            y_coordinate: values?.collectionPositionY,
            // user_id: cookies.auid,
            collection_data: values?.collection?.map((item, index) => {
              console.log("idtem", item);
              return {
                id: item?.collectionId,
                photo: item?.collectionImages,
                name: item?.collectionProductName,
                description: item?.collectionDescription,
                currency: item?.collectionCurrency,
                price: item?.collectionPrice,
                link: item?.collectionLink,
                position: index,
              };
            }),
          },
          token: cookies.t,
        });
        if (res) {
          setIsLoading(false);
          setModalState(() => ({
            isModalOpen: false,
          }));
          toast.success("The Collection Was Updated", {
            duration: 2000,
            style: confirmationStyles,
          });
          getCollectionDetails();
          getVideopreviewDataById();
        }
      } else {
        const res = await postData({
          endpoint: "VideoProduct/addProductCollection",
          params: {},
          data: {
            video_id: video_id,
            name: values?.collectionName,
            product_cta: values?.product_cta,
            start_time: values?.collectionStartTime,
            end_time: values?.collectionEndTime,
            x_coordinate: values?.collectionPositionX,
            y_coordinate: values?.collectionPositionY,
            user_id: cookies.auid,
            collection_data: values?.collection?.map((item, index) => {
              return {
                photo: item?.collectionImages,
                name: item?.collectionProductName,
                description: item?.collectionDescription,
                currency: item?.collectionCurrency,
                price: item?.collectionPrice,
                link: item?.collectionLink,
                position: index,
              };
            }),
          },
          token: cookies.t,
        });
        if (res) {
          setIsLoading(false);
          setModalState(() => ({
            isModalOpen: false,
          }));
          toast.success("The Collection Was Added", {
            duration: 2000,
            style: confirmationStyles,
          });
          getCollectionDetails();
          getVideopreviewDataById();
        }
      }
    } catch (exp) {
      setIsLoading(false);
      console.log("exp", exp);
    }
  };

  const handleImagesUpload = async (file) => {
    try {
      const res = await postFormData(
        "VideoProduct/addImage",
        { photo: file },
        { video_id: video_id },
        cookies.t
      );
      return res;
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  return (
    <div>
      <Formik
        initialValues={collectionInitialValues}
        onSubmit={collectionHandleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        resetForm
      >
        {({
          values,
          setFieldValue,
          isValid,
          dirty,
          touched,
          errors,
          setTouched,
        }) => (
          <>
            <div className="row g-3 mt-2">
              <InputLayout style={{ width: "40%" }}>
                <label htmlFor="url" className="mt-2">
                  Collection Name
                </label>
                <Field
                  type="text"
                  id="collectionName"
                  name="collectionName"
                  placeholder="Collection Name"
                />
              </InputLayout>
              <InputLayout style={{ width: "40%" }}>
                <label htmlFor="url" className="mt-2">
                  Collection CTA
                </label>
                <Field
                  type="text"
                  id="product_cta"
                  name="product_cta"
                  placeholder="Collection CTA"
                />
              </InputLayout>
            </div>
            <div className="row g-3 mt-2">
              <InputLayout style={{ width: "40%" }}>
                <label htmlFor="url">Start Time</label>

                <CustomTimePicker
                  placeholder="Start Time"
                  onChange={(time) => {
                    setFieldValue("collectionStartTime", time);
                  }}
                  selectedTime={values?.collectionStartTime}
                />
                {errors.collectionStartTime && (
                  <Error error={errors.collectionStartTime} />
                )}
              </InputLayout>
              <InputLayout style={{ width: "40%" }}>
                <label htmlFor="url">End Time</label>
                <CustomTimePicker
                  placeholder="End Time"
                  onChange={(time) => {
                    setTouched({ ...touched, collectionEndTime: true });
                    setFieldValue("collectionEndTime", time);
                    handleEndTimeChange(time);
                  }}
                  selectedTime={values?.collectionEndTime}
                />

                {maxTime ? (
                  <Error error={"The Time should be less than Player Time"} />
                ) : (
                  touched.collectionEndTime &&
                  errors.collectionEndTime && (
                    <Error error={errors.collectionEndTime} />
                  )
                )}
              </InputLayout>
            </div>

            <div className="row g-3 mt-2">
              <InputLayout style={{ width: "40%" }}>
                <label htmlFor="positionx">Position X</label>
                <Field
                  type="text"
                  id="collectionPositionX"
                  name="collectionPositionX"
                  placeholder="Position X"
                  disabled
                />
              </InputLayout>
              <InputLayout style={{ width: "40%" }}>
                <label htmlFor="positiony">Position Y</label>
                <Field
                  type="text"
                  id="collectionPositionY"
                  name="collectionPositionY"
                  placeholder="Position Y"
                  disabled
                />
              </InputLayout>
            </div>

            <FieldArray name="collection">
              {({ push, remove }) => (
                <>
                  {values?.collection?.map((pair, index) => (
                    <>
                      <div className={styles.addRemoveContainer}>
                        {index === 0 && (
                          <div
                            onClick={() =>
                              push({
                                collectionImages: null,
                                collectionName: null,
                                collectionDescription: null,
                                collectionPrice: null,
                                collectionLink: null,
                                collectionCurrency: null,
                              })
                            }
                          >
                            <span className={styles.addFeild}>
                              <Add className="" />
                              Add More
                            </span>
                          </div>
                        )}
                      </div>
                      <div key={index} className="mt-3">
                        <div
                          className="accordion"
                          id={`accordionExample${index}`}
                        >
                          <div
                            className="accordion-item"
                            style={{ position: "relative" }}
                          >
                            <h2
                              className="accordion-header"
                              id={`heading${index}`}
                              style={{ display: "flex" }}
                            >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                <h4
                                  className="normal-heading"
                                  style={{
                                    border: "none",
                                    marginBottom: "0",
                                    alignItems: "center",
                                    marginLeft: "25px",
                                    minWidth: "max-content",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                  }}
                                >
                                  {`Item ${index + 1}`}
                                </h4>
                                {index !== 0 && (
                                  <span
                                    className="cursor-pointer error-div"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: 700,
                                      marginTop: 0,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </span>
                                )}
                              </div>
                              <button
                                className={`accordion-button ${
                                  index === 0 ? "" : "collapsed"
                                }`}
                                style={{ width: "auto" }}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="false"
                                aria-controls={`collapse${index}`}
                              ></button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className={`accordion-collapse collapse ${
                                index === 0 ? "show" : ""
                              }`}
                              aria-labelledby={`heading${index}`}
                            >
                              <div className="accordion-body">
                                <>
                                  <label>Product Image</label>
                                  <ImageSelector
                                    handleFile={async (file) => {
                                      // setFieldValue("Collectionremove_profile_photo", false);
                                    }}
                                    selectedImage={replaceSpacesInImageUrl(
                                      values?.collection[index].collectionImages
                                    )}
                                    // selectedImages={
                                    //   values?.collection[index].photo
                                    // }
                                    onConfirm={async (file) => {
                                      const res = await handleImagesUpload(
                                        file
                                      );
                                      setFieldValue(
                                        `collection[${index}].collectionImages`,
                                        res
                                      );
                                    }}
                                    confirmationStatus={true}
                                    onRemove={() =>
                                      setFieldValue(
                                        `collection[${index}].collectionImages`,
                                        null
                                      )
                                    }
                                  />
                                </>

                                <InputLayout>
                                  <label htmlFor="url">Product Name</label>
                                  <Field
                                    type="text"
                                    id="product_name"
                                    name={`collection[${index}].collectionProductName`}
                                    placeholder="Product Name"
                                  />
                                </InputLayout>
                                <div className="mt-3">
                                  <InputLayout>
                                    <label htmlFor="url">
                                      Product Description
                                    </label>
                                    <Field
                                      type="text"
                                      id="product_description"
                                      name={`collection[${index}].collectionDescription`}
                                      placeholder="Product Description"
                                    />
                                  </InputLayout>
                                </div>
                                <div className="row g-3 mt-0">
                                  <InputLayout>
                                    <label htmlFor="url">Currency</label>
                                    <SelectDropdown
                                      data={countrybyCurrency}
                                      onChange={(option) => {
                                        console.log("option", option);
                                        setFieldValue(
                                          `collection[${index}].collectionCurrency`,
                                          option
                                        );
                                      }}
                                      placeholder="Currency"
                                      selectedValue={
                                        values?.collection[index]
                                          .collectionCurrency
                                      }
                                    />
                                    {console.log("----->valueschnage", values)}
                                  </InputLayout>
                                  <InputLayout>
                                    <label htmlFor="url">Product Price</label>
                                    <Field
                                      type="text"
                                      id="price"
                                      name={`collection[${index}].collectionPrice`}
                                      placeholder="Product Price"
                                    />
                                  </InputLayout>
                                </div>
                                <div className="mt-3 mb-4">
                                  <InputLayout>
                                    <label htmlFor="url">Product Link</label>
                                    <Field
                                      type="text"
                                      id="link"
                                      name={`collection[${index}].collectionLink`}
                                      placeholder="Product Link"
                                    />
                                  </InputLayout>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {console.log("values", values)} */}
                      </div>
                    </>
                  ))}
                </>
              )}
            </FieldArray>
            <div className="d-flex align-items-center  w-100 justify-content-end mt-4">
              <button
                className={`btn btn-outline-dark mt-0 me-2 w-20`}
                onClick={() => {
                  setModalState(() => ({
                    isModalOpen: false,
                  }));
                }}
              >
                Cancel
              </button>
              {videoEditTable?.id ? (
                <>
                  <button
                    className="btn btn-primary mt-0 me-2 w-20"
                    type="submit"
                    disabled={
                      !isValid ||
                      hoverItem?.positionx <= 0 ||
                      hoverItem?.positiony <= 0 ||
                      isLoading
                    }
                    onClick={() => {
                      collectionHandleSubmit(values);
                    }}
                  >
                    Update
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </>
              ) : (
                <button
                  className={`btn btn-primary mt-0 me-2 w-20`}
                  type="submit"
                  onClick={() => {
                    collectionHandleSubmit(values);
                  }}
                  disabled={!dirty || !isValid || isLoading}
                >
                  Add
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              )}
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
export default AddCollection;
