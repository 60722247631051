export const expirationTime = () => {
  const expirationTime = new Date();
  expirationTime.setHours(expirationTime.getHours() + 12); // Add 2 minutes
  return expirationTime;
};

// export const expirationTime = () => {
//   const expirationTime = new Date();
//   expirationTime.setMinutes(expirationTime.getMinutes() + 3); // Add 1 minute
//   console.log("time", expirationTime);
//   return expirationTime;
// };
