import React from "react";

const InputLayout = ({ children, classes, style }) => {
  return (
    <div className={`col-sm ${classes}`} style={style}>
      <div className="form-group">{children}</div>
    </div>
  );
};

export default InputLayout;
