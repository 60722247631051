const data = [
  {
    name: "United Arab Emirates",
    code: "AE",
    capital: "Abu Dhabi",
    continent: "Asia",
    currency: {
      code: "AED",
      name: "United Arab Emirates Dirham",
      name_plural: "UAE dirhams",
      symbol: "د.إ",
      symbol_native: "د.إ.",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Aruba",
    code: "AW",
    capital: "Oranjestad",
    continent: "North America",
    currency: {
      code: "AWG",
      name: "Aruban Florin",
      name_plural: "Aruban Florin",
      symbol: "ƒ",
      symbol_native: "Afl.",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Australia",
    code: "AU",
    capital: "Canberra",
    continent: "Oceania",
    currency: {
      code: "AUD",
      name: "Australian Dollar",
      name_plural: "Australian dollars",
      symbol: "A$",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Azerbaijan",
    code: "AM",
    capital: "Baku",
    continent: "Asia",
    currency: {
      code: "AZN",
      name: "Azerbaijani Manat",
      name_plural: "Azerbaijani manats",
      symbol: "₼",
      symbol_native: "₼",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Bangladesh",
    code: "BD",
    capital: "Dhaka",
    continent: "Asia",
    currency: {
      code: "BDT",
      name: "Bangladeshi Taka",
      name_plural: "Bangladeshi takas",
      symbol: "৳",
      symbol_native: "৳",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Bahrain",
    code: "BH",
    capital: "Manama",
    continent: "Asia",
    currency: {
      code: "BHD",
      name: "Bahraini Dinar",
      name_plural: "Bahraini dinars",
      symbol: "د.ب",
      symbol_native: "د.ب.‏",
      decimal_digits: 3,
      rounding: 0,
    },
  },
  {
    name: "Brazil",
    code: "BR",
    capital: "Brasília",
    continent: "South America",
    currency: {
      code: "BRL",
      name: "Brazilian Real",
      name_plural: "Brazilian reals",
      symbol: "R$",
      symbol_native: "R$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Belarus",
    code: "BY",
    capital: "Minsk",
    continent: "Europe",
    currency: {
      code: "BYN",
      name: "Belarusian Ruble",
      name_plural: "Belarusian rubles",
      symbol: "р.",
      symbol_native: "руб.",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Canada",
    code: "CA",
    capital: "Ottawa",
    continent: "North America",
    currency: {
      code: "CAD",
      name: "Canadian Dollar",
      name_plural: "Canadian dollars",
      symbol: "C$",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Switzerland",
    code: "CH",
    capital: "Bern",
    continent: "Europe",
    currency: {
      code: "CHF",
      name: "Swiss Franc",
      name_plural: "Swiss francs",
      symbol: "Fr",
      symbol_native: "CHF",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Chile",
    code: "CL",
    capital: "Santiago",
    continent: "South America",
    currency: {
      code: "CLP",
      name: "Chilean Peso",
      name_plural: "Chilean pesos",
      symbol: "CL$",
      symbol_native: "$",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "China",
    code: "CN",
    capital: "Beijing",
    continent: "Asia",
    currency: {
      code: "CNY",
      name: "Chinese Yuan",
      name_plural: "Chinese yuan",
      symbol: "¥",
      symbol_native: "CN¥",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Costa Rica",
    code: "CR",
    capital: "San José",
    continent: "North America",
    currency: {
        code: "CRC",
        name: "Costa Rican Colón",
        name_plural: "Costa Rican colóns",
        symbol: "₡",
        symbol_native: "₡",
        decimal_digits: 0,
        rounding: 0,
    },
  },
  {
    name: "Czechia",
    code: "CZ",
    capital: "Prague",
    continent: "Europe",
    currency: {
      code: "CZK",
      name: "Czech Republic Koruna",
      name_plural: "Czech Republic korunas",
      symbol: "Kč",
      symbol_native: "Kč",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Germany",
    code: "DE",
    capital: "Berlin",
    continent: "Europe",
    currency: {
      code: "EUR",
      name: "Euro",
      name_plural: "euros",
      symbol: "€",
      symbol_native: "€",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Ghana",
    code: "GH",
    capital: "Accra",
    continent: "Africa",
    currency: {
      code: "GHS",
      name: "Ghanaian Cedi",
      name_plural: "Ghanaian cedis",
      symbol: "₵",
      symbol_native: "GH₵",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "United Kingdom",
    code: "GB",
    capital: "London",
    continent: "Europe",
    currency: {
      code: "GBP",
      name: "British Pound Sterling",
      name_plural: "British pounds sterling",
      symbol: "£",
      symbol_native: "£",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Hong Kong",
    code: "HK",
    capital: "Hong Kong",
    continent: "Asia",
    currency: {
      code: "HKD",
      name: "Hong Kong Dollar",
      name_plural: "Hong Kong dollars",
      symbol: "HK$",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Croatia",
    code: "HR",
    capital: "Zagreb",
    continent: "Europe",
    currency: {
        code: "HRK",
        name: "Croatian Kuna",
        name_plural: "Croatian kunas",
        symbol: "kn",
        symbol_native: "kn",
        decimal_digits: 2,
        rounding: 0,
    },
  },
  {
    name: "Hungary",
    code: "HU",
    capital: "Budapest",
    continent: "Europe",
    currency: {
      code: "HUF",
      name: "Hungarian Forint",
      name_plural: "Hungarian forints",
      symbol: "Ft",
      symbol_native: "Ft",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Israel",
    code: "IL",
    capital: "Jerusalem",
    continent: "Asia",
    currency: {
      code: "ILS",
      name: "Israeli New Sheqel",
      name_plural: "Israeli new sheqels",
      symbol: "₪",
      symbol_native: "₪",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "India",
    code: "IN",
    capital: "New Delhi",
    continent: "Asia",
    currency: {
      code: "INR",
      name: "Indian Rupee",
      name_plural: "Indian rupees",
      symbol: "₹",
      symbol_native: "₹",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Indonesia",
    code: "ID",
    capital: "Jakarta",
    continent: "Asia",
    currency: {
      code: "IDR",
      name: "Indonesian Rupiah",
      name_plural: "Indonesian rupiahs",
      symbol: "Rp",
      symbol_native: "Rp",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Japan",
    code: "JP",
    capital: "Tokyo",
    continent: "Asia",
    currency: {
      code: "JPY",
      name: "Japanese Yen",
      name_plural: "Japanese yen",
      symbol: "¥",
      symbol_native: "￥",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    capital: "Astana",
    continent: "Asia",
    currency: {
      code: "KZT",
      name: "Kazakhstani Tenge",
      name_plural: "Kazakhstani tenges",
      symbol: "₸",
      symbol_native: "тңг.",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "South Korea",
    code: "KR",
    capital: "Seoul",
    continent: "Asia",
    currency: {
      code: "KRW",
      name: "South Korean Won",
      name_plural: "South Korean won",
      symbol: "₩",
      symbol_native: "₩",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Laos",
    code: "LA",
    capital: "Vientiane",
    continent: "Asia",
    currency: {
      code: "LAK",
      name: "Lao kip",
      name_plural: "Lao kip",
      symbol: "₭",
      symbol_native: "₭",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Sri Lanka",
    code: "LK",
    capital: "Colombo",
    continent: "Asia",
    currency: {
      code: "LKR",
      name: "Sri Lankan Rupee",
      name_plural: "Sri Lankan rupees",
      symbol: "SLRs",
      symbol_native: "SL Re",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Myanmar [Burma]",
    code: "MM",
    capital: "Naypyitaw",
    continent: "Asia",
    currency: {
      code: "MMK",
      name: "Myanma Kyat",
      name_plural: "Myanma kyats",
      symbol: "K",
      symbol_native: "K",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Malaysia",
    code: "MY",
    capital: "Kuala Lumpur",
    continent: "Asia",
    currency: {
      code: "MYR",
      name: "Malaysian Ringgit",
      name_plural: "Malaysian ringgits",
      symbol: "RM",
      symbol_native: "RM",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Mongolia",
    code: "MN",
    capital: "Ulan Bator",
    continent: "Asia",
    currency: {
      code: "MNT",
      name: "Mongolian tögrög",
      name_plural: "Mongolian tögrög",
      symbol: "₮",
      symbol_native: "₮",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "Mexico",
    code: "MX",
    capital: "Mexico City",
    continent: "North America",
    currency: {
        code: "MXN",
        name: "Mexican Peso",
        name_plural: "Mexican pesos",
        symbol: "MX$",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
    },
  },
  {
    name: "Nigeria",
    code: "NG",
    capital: "Abuja",
    continent: "Africa",
    currency: {
        code: "NGN",
        name: "Nigerian Naira",
        name_plural: "Nigerian nairas",
        symbol: "₦",
        symbol_native: "₦",
        decimal_digits: 2,
        rounding: 0,
    },
  },
  {
    name: "New Zealand",
    code: "NZ",
    capital: "Wellington",
    continent: "Oceania",
    currency: {
      code: "NZD",
      name: "New Zealand Dollar",
      name_plural: "New Zealand dollars",
      symbol: "NZ$",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Philippines",
    code: "PH",
    capital: "Manila",
    continent: "Asia",
    currency: {
      code: "PHP",
      name: "Philippine Peso",
      name_plural: "Philippine pesos",
      symbol: "₱",
      symbol_native: "₱",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Poland",
    code: "PL",
    capital: "Warsaw",
    continent: "Europe",
    currency: {
      code: "PLN",
      name: "Polish Zloty",
      name_plural: "Polish zlotys",
      symbol: "zł",
      symbol_native: "zł",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Paraguay",
    code: "PY",
    capital: "Asunción",
    continent: "South America",
    currency: {
        code: "PYG",
        name: "Paraguayan Guarani",
        name_plural: "Paraguayan guaranis",
        symbol: "₲",
        symbol_native: "₲",
        decimal_digits: 0,
        rounding: 0,
    },
  },
  {
    name: "Romania",
    code: "RO",
    capital: "Bucharest",
    continent: "Europe",
    currency: {
      code: "RON",
      name: "Romanian Leu",
      name_plural: "Romanian lei",
      symbol: "L",
      symbol_native: "L",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Russia",
    code: "RU",
    capital: "Moscow",
    continent: "Europe",
    currency: {
      code: "RUB",
      name: "Russian Ruble",
      name_plural: "Russian rubles",
      symbol: "₽",
      symbol_native: "₽.",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Sweden",
    code: "SE",
    capital: "Stockholm",
    continent: "Europe",
    currency: {
      code: "SEK",
      name: "Swedish Krona",
      name_plural: "Swedish kronor",
      symbol: "kr",
      symbol_native: "kr",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Singapore",
    code: "SG",
    capital: "Singapore",
    continent: "Asia",
    currency: {
      code: "SGD",
      name: "Singapore Dollar",
      name_plural: "Singapore dollars",
      symbol: "S$",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Thailand",
    code: "TH",
    capital: "Bangkok",
    continent: "Asia",
    currency: {
      code: "THB",
      name: "Thai Baht",
      name_plural: "Thai baht",
      symbol: "฿",
      symbol_native: "฿",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Turkey",
    code: "TR",
    capital: "Ankara",
    continent: "Asia",
    currency: {
      code: "TRY",
      name: "Turkish Lira",
      name_plural: "Turkish Lira",
      symbol: "₺",
      symbol_native: "₺",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Ukraine",
    code: "UA",
    capital: "Kiev",
    continent: "Europe",
    currency: {
        code: "UAH",
        name: "Ukrainian Hryvnia",
        name_plural: "Ukrainian hryvnias",
        symbol: "₴",
        symbol_native: "₴",
        decimal_digits: 2,
        rounding: 0,
    },
  },
  {
    name: "United States",
    code: "US",
    capital: "Washington",
    continent: "North America",
    currency: {
      code: "USD",
      name: "US Dollar",
      name_plural: "US dollars",
      symbol: "$",
      symbol_native: "$",
      decimal_digits: 2,
      rounding: 0,
    },
  },
  {
    name: "Vietnam",
    code: "VN",
    capital: "Hanoi",
    continent: "Asia",
    currency: {
      code: "VND",
      name: "Vietnamese Dong",
      name_plural: "Vietnamese dong",
      symbol: "₫",
      symbol_native: "₫",
      decimal_digits: 0,
      rounding: 0,
    },
  },
  {
    name: "South Africa",
    code: "ZA",
    capital: "Pretoria",
    continent: "Africa",
    currency: {
      code: "ZAR",
      name: "South African Rand",
      name_plural: "South African Rand",
      symbol: "R",
      symbol_native: "R",
      decimal_digits: 2,
      rounding: 0,
    },
  }
];

export const countrybyCurrency = data?.map((item) => {
  return {
    value: item?.currency?.code,
    label: item?.name + " - " + item?.currency?.code,
  };
});
