export const statusData = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];
export const userRoles = [
  { label: "Admin ", value: "admin" },
  { label: "Manager", value: "manager" },
  { label: "Viewer", value: "viewer" },
];
export const TypeData = [
  { label: "Investor", value: 1 },
  { label: "Manager/Sponsor", value: 2 },
];
export const statusOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Reviewing", label: "Reviewing" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Closed", label: "Closed" },
];
