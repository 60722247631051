import PageCard from "../../atoms/PageCard";
import { getData, postData } from "../../../services";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { firstLetterCapital } from "../../../utils/Helpers/helper";
import EditIcon from "../../icons/editIcon";
import styles from "./index.module.scss";
import Modal from "../../atoms/Modals/Modal";
import { expirationTime } from "../../../helpers/expirationTime";
import { useSelector } from "react-redux";
import { RoleData } from "../../../utils/staticData/roleData";

const Profile = () => {
  const [cookies, setCookie] = useCookies(["t", "auid", "bid"]);
  const [businessProfileData, setBusinessProfileData] = useState();
  const [editToggle, setEditToggle] = useState(false);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const getBusinessProfileDetails = async () => {
    try {
      const res = await getData({
        endpoint: "BusinessUser/getUserProfile",
        params: {
          user_id: cookies.auid,
        },
        token: cookies.t,
      });
      if (res) {
        setCookie("bskey", res?.secret_key, {
          path: "/",
          expires: expirationTime(),
        });
        setBusinessProfileData(res);
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  useEffect(() => {
    getBusinessProfileDetails();
  }, []);

  const handleRegenerateSecretKey = async () => {
    try {
      const response = await postData({
        endpoint: "BusinessManagement/generateSecretKey",
        params: {
          business_id: cookies.bid,
        },
      });
      if (response) {
        getBusinessProfileDetails();
        setEditToggle(false);
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };

  return (
    <div>
      <PageCard>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Name</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {firstLetterCapital(businessProfileData?.name)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Phone</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {firstLetterCapital(businessProfileData?.phone)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Business Name</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {firstLetterCapital(businessProfileData?.business_name)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Business Contact</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {firstLetterCapital(businessProfileData?.business_contact)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Business Type</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {firstLetterCapital(businessProfileData?.business_type)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Business Domain</div>
              </div>
              <div className="col-md">
                <div className="view-people-info">
                  {firstLetterCapital(businessProfileData?.business_domain)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md">
            <div className="row">
              <div className="col-md">
                <div className="view-people-label">Secret Key</div>
              </div>
              <div className="col-md">
                <div className={styles.viewPeopleInfo}>
                  <>{firstLetterCapital(businessProfileData?.secret_key)}</>
                  {userInfo?.role !== RoleData.VIEWER && (
                    <span
                      className={styles.editIcon}
                      onClick={() => {
                        setEditToggle(!editToggle);
                      }}
                    >
                      <EditIcon />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageCard>
      <Modal
        isActive={editToggle}
        onClose={() => {
          setEditToggle(false);
        }}
        title="Regenerate secret key"
      >
        <>
          <div>Are you sure you want to generate a new secret key?</div>
          <div className="d-flex align-items-center  w-100 justify-content-end mt-4">
            <button
              className="btn btn-primary mt-0 me-2"
              type="button"
              onClick={() => {
                setEditToggle(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mt-0 me-2"
              type="button"
              onClick={(e) => {
                handleRegenerateSecretKey();
              }}
            >
              Ok
            </button>
          </div>
        </>
      </Modal>
    </div>
  );
};
export default Profile;
