import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import IconCircle from "../../avatars/IconCircle";

const InfoCard = ({
  icon,
  svgIcon,
  isSvg,
  color,
  title,
  data,
  unit,
  link,
  style,
  dotIcon,
  setModalState,
}) => {
  const navigate = useNavigate();

  const onClickHandle = (e) => {
    e.preventDefault();
    if (link) navigate(link);
  };

  return (
    <div
      className="info-card cursor-pointer"
      onClick={onClickHandle}
      style={style}
    >
      <div>
        {isSvg ? (
          <IconCircle
            svgIcon={svgIcon}
            color={color}
            size="56px"
            // iconSize="24px"
            isSvg={true}
          />
        ) : (
          <IconCircle icon={icon} color={color} size="56px" />
        )}
      </div>
      <div className="info-card-detail" style={{ marginLeft: "14px" }}>
        <span
          className="text-dark heading"
          style={{ fontSize: "24px", fontWeight: "600" }}
        >
          {data}&nbsp;<span className="p-lg">{unit ? unit : ""}</span>
        </span>
        <span className="view-muted" style={{ fontSize: "14px" }}>
          {title}
        </span>
      </div>
    </div>
  );
};

export default InfoCard;
