import { useEffect, useState } from "react";
import InfoCard from "../../atoms/InfoCard";
import PageHeader from "../../atoms/PageHeader";
import DateRangePickerShortCuts from "../../atoms/dateRangePicker";
import Building from "../../icons/Building";
import UserIcon from "../../icons/UserIcon";
import Video from "../../icons/Video";
import moment from "moment";
import { getData } from "../../../services";
import { useCookies } from "react-cookie";

const HomePage = () => {
  const [cookies] = useCookies(["t", "bid"]);
  const todays = new Date();
  const thirtyDaysAgo = moment(todays).subtract(30, "days").startOf("day");
  const [date, setDate] = useState({
    start_date: thirtyDaysAgo.toISOString(),
    end_date: todays.toISOString(),
  });
  const [dashboardData, setDashboardData] = useState();
  const getDashboardDetails = async () => {
    try {
      const response = await getData({
        endpoint: "/Dashboard/getDashboardDataCount",
        params: {
          business_id: cookies.bid,
          start_date: date.start_date,
          end_date: date.end_date,
        },
        token: cookies.t,
      });
      setDashboardData(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDashboardDetails();
  }, [date]);

  const padZero = (num) => {
    return num < 10 ? "0" + num : num;
  };

  // Format duration into HH:MM:SS
  const formattedDuration =
    padZero(dashboardData?.video_duration?.hours) +
    ":" +
    padZero(dashboardData?.video_duration?.minutes) +
    ":" +
    padZero(dashboardData?.video_duration?.seconds);
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <PageHeader title="Dashboard" />
        <DateRangePickerShortCuts
          setDate={setDate}
          className="charts-datapicker-container"
        />
      </div>
      <div className="info-card-container">
        <InfoCard
          color="#B1E5FC"
          svgIcon={<Video />}
          isSvg={true}
          title="Videos"
          data={dashboardData?.videos_count || 0}
        />
        <InfoCard
          color="#FFD88D"
          svgIcon={<Video />}
          isSvg={true}
          title="Total Videos Duration"
          data={
            formattedDuration === "undefined:undefined:undefined"
              ? "00:00:00"
              : formattedDuration
          }
        />
        <InfoCard
          color="#FFD88D"
          svgIcon={<Video />}
          isSvg={true}
          title="Total Views"
          data={dashboardData?.video_click_count || 0}
        />
        <InfoCard
          color="#FFD88D"
          svgIcon={<Video />}
          isSvg={true}
          title="Total Objects Click"
          data={dashboardData?.object_click_count || 0}
        />
        <InfoCard
          color="#FFD88D"
          svgIcon={<UserIcon />}
          isSvg={true}
          title="User Management"
          data={dashboardData?.user_count || 0}
        />
      </div>
    </>
  );
};
export default HomePage;
