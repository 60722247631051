import { useCookies } from "react-cookie";
import "./circlerAvatar.scss";

const CircleAvatar = ({
  name,
  color,
  size,
  fontSize = "10px",
  border,
  fontColor,
}) => {
  const [cookies] = useCookies(["aun"]);
  // Check if the name is empty or undefined, and provide a default value
  let displayName = name || cookies.aun || "New User";

  displayName = displayName.replace(/\s+/g, " ").trim();

  // Split the name into first and last name based on whitespace
  const [firstName, lastName] = displayName.split(" ");

  // Handle the case where there's only one word in the name
  const abbreviatedName =
    firstName.length > 1
      ? `${firstName.charAt(0)}${lastName ? lastName.charAt(0) : ""}`
      : firstName;

  const backgroundColor = color || "#83BF6E";

  return (
    <div
      className="profile-image cursor-pointer"
      style={{
        backgroundColor,
        width: size,
        height: size,
        fontSize,
        border: border,
      }}
    >
      <span style={{ fontSize: fontSize, color: fontColor }}>
        {abbreviatedName}
      </span>
    </div>
  );
};

export default CircleAvatar;
