import React, { useRef, useState, useEffect } from "react";
import videoSrc from "../Demo.mp4";
import "./style.scss";
import imageUrl from "../demoimage.jpg";
import Cart from "../../../icons/cart";
import { getData } from "../../../../services";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import CloseBtnIcom from "../../../icons/CloseBtnIcom";
import { colors } from "@mui/material";
import ArrowLeft from "../../../icons/arrowLeft";
import ArrowRigth from "../../../icons/arrowRight";
import FullScreen from "../../../icons/fullScreen";

const PreviewVideo = () => {
  const videoRef = useRef(null);
  const [cookies] = useCookies(["t", "bskey"]);
  const { id } = useParams();
  const [videoData, setVideoData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [bodyState, setBodyState] = useState(false);
  const [videoWidth, setVideoWidth] = useState();
  const [totalItemsCart, setTotalItemsCart] = useState();
  const [totalIndex, setTotalIndex] = useState(0);
  const [fullScreen, setIsFullScreen] = useState(false);
  const [collectionData, setCollectionData] = useState([]);



  useEffect(() => {
    const getWidth = videoRef.current.getBoundingClientRect().width;
    setVideoWidth(getWidth);
  }, []);

  const getHoverPosition = ({ data }) => {
    if (data) {
      data?.product_description.forEach((item, index) => {
        const videoStyles = videoRef.current.getBoundingClientRect();
        const hoveredItem = document.getElementById(`hover-container-${index}`);
        const hoverStyles = hoveredItem.getBoundingClientRect();

        const itemLeft = (item.x_coordinate * videoStyles.width) / 100 - 10;
        const itemTop = (item.y_coordinate * videoStyles.height) / 100 - 10;

        // Optimize repositioning logic
        console.log("v  :", hoverStyles);
        if (itemLeft + 150 > videoStyles.width) {
          hoveredItem.style.left = `-150px`;
          hoveredItem.style.right = "auto";
        }
      });
      data?.product_card.forEach((item, index) => {
        const videoStyles = videoRef.current.getBoundingClientRect();
        const hoveredItem = document.getElementById(`hov-container-${index}`);
        const hoverStyles = hoveredItem.getBoundingClientRect();

        const itemLeft = (item.x_coordinate * videoStyles.width) / 100 - 10;
        const itemTop = (item.y_coordinate * videoStyles.height) / 100 - 10;
        if (
          videoStyles.height - itemTop + 200 > videoStyles.height ||
          hoverStyles.top < 0
        ) {
          hoveredItem.style.top = "40px";
          hoveredItem.style.left = "auto";
          hoveredItem.style.bottom = "auto";
        } else {
          hoveredItem.style.bottom = "40px";
          hoveredItem.style.left = "auto";
          hoveredItem.style.top = "auto";
        }
      });
    }
  };



  const getVideoDataById = async () => {
    try {
      const response = await getData({
        endpoint: "Video/getVideoPreview",
        params: {
          video_id: id,
        },
        token: cookies.t,
        secretkey: cookies.bskey,
      });
      setVideoData(response);
      if (response) getHoverPosition({ data: response });
    } catch (error) {
      console.log(error);
    }
  };
  const getCollectionDataById = async () => {
    try {
      const response = await getData({
        endpoint: "VideoProduct/getAllProductCollection",
        params: {
          video_id: id,
        },
        token: cookies.t,
        secretkey: cookies.bskey,
      });
      setCollectionData(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getVideoDataById();
    getCollectionDataById();
  }, []);

  // Function to handle time update in the video
  const handleTimeUpdate = () => {
    setCurrentTime(videoRef?.current.currentTime);
  };
  // Function to check if the current time is within the range of any item
  const checkTimeInRange = ({ time, item }) => {
    // console.log(item?.start_time, item?.end_time);
    function calculateTotalSeconds(timeString) {
      // Split the time string into hours, minutes, and seconds
      var parts = timeString.split(":");

      // Convert hours, minutes, and seconds into integers
      var hours = parseInt(parts[0], 10);
      var minutes = parseInt(parts[1], 10);
      var seconds = parseInt(parts[2], 10);

      // Calculate total seconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds;

      return totalSeconds;
    }
    const startTime = calculateTotalSeconds(item.start_time);
    const endTime = calculateTotalSeconds(item.end_time);
    return time >= startTime && time <= endTime;
  };
  const checkTimeInRangeColection = ({ time, item }) => {
    // console.log(item?.start_time, item?.end_time);
    function calculateTotalSeconds(timeString) {
      // Split the time string into hours, minutes, and seconds
      var parts = timeString.split(":");

      // Convert hours, minutes, and seconds into integers
      var hours = parseInt(parts[0], 10);
      var minutes = parseInt(parts[1], 10);
      var seconds = parseInt(parts[2], 10);

      // Calculate total seconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds;

      return totalSeconds;
    }
    const startTime = calculateTotalSeconds(item.start_date);
    const endTime = calculateTotalSeconds(item.end_date);
    return time >= startTime && time <= endTime;
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    // Add event listeners for time update
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      // Cleanup event listeners
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);
  const [videoStatus, setVideoStatus] = useState(false);

  const handleItemHover = ({ index, type, item }) => {
    console.log("2-entered");
    console.log("index  :", index, type);
    const videoStyles = videoRef.current.getBoundingClientRect();
    const hoveredItem = document.getElementById(
      type === "Des" ? `hover-container-${index}` : `hov-container-${index}`
    );
    const hoverStyles = hoveredItem.getBoundingClientRect();

    const itemLeft = (item.x_coordinate * videoStyles.width) / 100 - 10;
    const itemTop = (item.y_coordinate * videoStyles.height) / 100 - 10;

    // Optimize repositioning logic
    console.log("v  :", hoverStyles);
    if (type === "Des" && itemLeft + 150 > videoStyles.width) {
      hoveredItem.style.left = `-150px`;
      hoveredItem.style.right = "auto";
    }
    console.log("X-Axis :", hoverStyles.top);
    if (type === "Cart") {
      if (
        videoStyles.height - itemTop + 200 > videoStyles.height ||
        hoverStyles.top < 0
      ) {
        hoveredItem.style.top = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.bottom = "auto";
      } else {
        hoveredItem.style.bottom = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.top = "auto";
      }
    }
    // else {
    //     hoveredItem.style.bottom = "40px";
    //     hoveredItem.style.left = "auto";
    //     hoveredItem.style.top = "auto";
    //   }
    // }
    let isTopNegative = false;

    const handleScroll = () => {
      const hoverStyles = hoveredItem.getBoundingClientRect();

      if (!isTopNegative && hoverStyles.top < 0 && type === "Cart") {
        console.log("1-entered");
        hoveredItem.style.top = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.bottom = "auto";
        isTopNegative = true; // Set the flag to true to indicate that the condition has been met
      } else if (isTopNegative && hoverStyles.top >= 0 && type === "Cart") {
        // If top is no longer negative, reset the styles and the flag
        console.log("2-entered");
        hoveredItem.style.bottom = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.top = "auto";
        isTopNegative = false;
      }
    };
    if (videoRef?.current?.paused) {
      setVideoStatus(true);
    } else {
      setVideoStatus(false);
    }

    videoRef.current.pause();

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount or when handleItemHover is called again
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  };

  const handleItemHoverRemove = () => {
    // if (videoRef && videoRef.current) {
    //   if (!videoStatus && videoRef.current.readyState >= 10) {
    //     videoRef.current.pause();
    //   } else if (videoStatus && videoRef.current.readyState >= 10) {
    //     videoRef.current.play();
    //   }
    // }
    if (currentTime !== 0) videoRef.current.play();
  };
  console.log("total  :", totalIndex);
  const containerRef = useRef(null);
  function toggleFullscreen() {
    let elem = containerRef.current;
    elem.onfullscreenchange = handleFullscreenChange;
    if (!document.fullscreenElement) {
      elem
        .requestFullscreen()
        .then({})
        .catch((err) => {
          alert(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
          );
        });
    } else {
      document.exitFullscreen();
    }
  }

  function handleFullscreenChange(event) {
    let elem = event.target;
    let isFullscreen = document.fullscreenElement === elem;

    adjustMyControls(isFullscreen);
  }

  function adjustMyControls(bool) {
    if (bool) {
      document.querySelector("video#v").classList.add("fullscreen");
      setIsFullScreen(true);
    } else {
      document.querySelector("video#v").classList.remove("fullscreen");
      setIsFullScreen(false);
    }
  }
  console.log(collectionData?.data);

  return (
    <div className="video-add-conatiner-main">
      <div style={{ position: "relative" }} id="container" ref={containerRef}>
        <video
          id="v"
          className="video-container"
          ref={videoRef}
          src={videoData?.video}
          controls
          controlsList="nofullscreen"
        ></video>
        <span
          id="go_fullscreen"
          onClick={toggleFullscreen}
          className={fullScreen ? "full_screen_css" : "half_screen_css"}
        >
          <FullScreen />
        </span>
        {videoData?.product_description && (
          <>
            {videoData?.product_description?.map(
              (item, index) =>
                checkTimeInRange({
                  time: currentTime,
                  item: item,
                }) && (
                  <div
                    key={index}
                    className="main-item-wrap"
                    style={{
                      position: "absolute",
                      top: `calc(${item.y_coordinate}% - 10px)`,
                      left: `calc(${item.x_coordinate}% - 10px)`,
                    }}
                  >
                    <div
                      className="item"
                      onMouseEnter={() =>
                        handleItemHover({ index, type: "Des", item })
                      }
                      onMouseLeave={() =>
                        handleItemHoverRemove({ index, type: "Cart" })
                      }
                    >
                      <div className="item-dot-container"></div>
                    </div>
                    <div
                      className="item-content-display extra-desc-styles"
                      style={{ left: "40px" }}
                      id={`hover-container-${index}`}
                    >
                      {item?.photo && (
                        <img
                          src={item?.photo}
                          className="item-description-image"
                        />
                      )}
                      <div className="item-content-display-hover">
                        <h3>{item?.name}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
        )}
      
        {videoData?.product_card && (
          <>
            {videoData?.product_card?.map(
              (item, index) =>
                checkTimeInRange({
                  time: currentTime,
                  item: item,
                }) && (
                  <div
                    key={index}
                    className="main-item-wrap"
                    style={{
                      position: "absolute",
                      top: `calc(${item.y_coordinate}% - 10px)`,
                      left: `calc(${item.x_coordinate}% - 10px)`,
                    }}
                  >
                    <div
                      className="item shopping-item-icon-container"
                      onMouseEnter={() =>
                        handleItemHover({ index, type: "Cart", item })
                      }
                      onMouseLeave={() =>
                        handleItemHoverRemove({ index, type: "Cart" })
                      }
                    >
                      <div className="item-shopping-icon ">
                        <Cart />
                      </div>
                    </div>
                    <div
                      className="item-content-display extra-card-styles"
                      style={{ bottom: "40px" }}
                      id={`hov-container-${index}`}
                    >
                      {item?.photo && <img src={item?.photo} />}
                      <div className="item-content-display-hover">
                        <h3>{item?.name}</h3>
                        <p>{item?.description}</p>
                        <h4>{`${item?.currency} ${item?.price}`}</h4>
                        <button
                          className="btn cart-btn-continer"
                          onClick={() => item?.link}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
        )}
        {videoData?.product_highlight && (
          <>
            {videoData?.product_highlight?.map(
              (item, index) =>
                checkTimeInRange({
                  time: currentTime,
                  item: item,
                }) && (
                  <div
                    key={index}
                    className={
                      (item.x_coordinate * videoWidth) / 100 > videoWidth / 2
                        ? "item-highlight"
                        : "item-highlight-left"
                    }
                    style={{
                      position: "absolute",
                      top: `${item.y_coordinate}%`,
                      left: `${item.x_coordinate}%`,
                    }}
                    // onMouseEnter={handleItemHover}
                    // onMouseLeave={handleItemHoverRemove}
                  >
                    <div className="item-highlight-container">{item?.name}</div>
                    <div className="line"></div>
                  </div>
                )
            )}
          </>
        )}
        {collectionData?.data && (
          <>
            {collectionData.data.map(
              (item, index) =>
                checkTimeInRangeColection({
                  time: currentTime,
                  item: item,
                }) && (
                  <>
                    {console.log(index, item)}
                    <div
                      className="Total-shop-collection"
                      onClick={() => {
                        setTotalItemsCart(item?.id);
                        setTotalIndex(0);
                      }}
                      style={{
                        top: `${item?.y_coordinate}%`,
                        left: `${item?.x_coordinate}%`,
                      }}
                    >
                      Shop the collection
                    </div>
                    {item?.id === totalItemsCart && (
                      <>
                        {console.log(
                          "final ====> ",
                          item?.id === totalItemsCart
                        )}
                        <div key={index} className="total-collection-wrap-main">
                          <div className="total-collection-outer-wrap">
                            {item?.collection_data?.length > 3 && (
                              <div
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  marginRight: "15px",
                                }}
                                onClick={() => {
                                  if (totalIndex > 0) {
                                    setTotalIndex(totalIndex - 1);
                                  }
                                }}
                              >
                                <ArrowLeft
                                  className={
                                    totalIndex > 0 ? "right-collection-btn" : ""
                                  }
                                />
                              </div>
                            )}
                            <div className="total-collection-inner-wrap">
                              <div
                                className="total_collection_wrap"
                                style={{
                                  transform: `translate3d(calc(${totalIndex} * -165px), 0, 0)`,
                                }}
                              >
                                {console.log("item  :", item)}
                                {item?.collection_data?.map((item, index) => (
                                  <div
                                    className="total-collection-container item-content-display extra-card-styles"
                                    key={index}
                                  >
                                    {item?.photo && <img src={item?.photo} />}
                                    <div className="item-content-display-hover">
                                      <h3>{item?.name}</h3>
                                      <p>{item?.description}</p>
                                      <h4>{`${item?.currency} ${item?.price}`}</h4>
                                      <button
                                        className="btn cart-btn-continer"
                                        onClick={() => {
                                          /* Define what happens on click */
                                        }}
                                      >
                                        Add to Cart
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {item?.collection_data?.length > 3 && (
                              <div
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    item?.collection_data?.length - 3 >=
                                    totalIndex + 1
                                  )
                                    setTotalIndex(totalIndex + 1);
                                }}
                              >
                                <ArrowRigth
                                  className={
                                    item?.collection_data?.length - 3 >=
                                    totalIndex + 1
                                      ? "right-collection-btn"
                                      : ""
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="total-cross-btn-collection"
                          onClick={() => {
                            setTotalItemsCart(null);
                            setTotalIndex(0);
                          }}
                        >
                          <CloseBtnIcom />
                        </div>
                      </>
                    )}
                  </>
                )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PreviewVideo;
