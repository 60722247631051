import React from "react";
import "./tabStrip.scss";

const TabStrip = ({
  activeTab,
  onTabChange,
  data,
  allowedTabId,
  areTabsDisabled,
  type,
}) => {
  return (
    <div
      style={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        borderBottom: "1px solid #CED5DE",
      }}
      id="tabstrip"
    >
      {data?.map((item) => {
        const isTabDisabled =
          areTabsDisabled &&
          allowedTabId !== undefined &&
          item.id !== allowedTabId;

        return (
          <>
            {type === "small" ? (
              <button
                key={item.id}
                onClick={(e) => onTabChange(e, item.label)}
                className={activeTab === item.label ? "selected" : ""}
                style={{
                  fontWeight: activeTab === item.label ? "500" : "400",
                  color: activeTab === item.label ? "#000" : "black",
                  textTransform: "capitalize",
                  paddingTop: "30px",
                  border: "none",
                  borderBottom:
                    activeTab === item.label
                      ? "2px solid #3D9BE9"
                      : "2px solid transparent",
                  paddingLeft: "10px",
                  background: "transparent",
                  padding: "10px 20px",
                  display: "inline-block",
                  fontSize: "14px",
                }}
                disabled={isTabDisabled}
              >
                {item.label}
              </button>
            ) : (
              <button
                key={item.id}
                onClick={(e) => onTabChange(e, item.label)}
                className={activeTab === item.label ? "selected" : ""}
                style={{
                  fontWeight: activeTab === item.label ? "500" : "400",
                  color: activeTab === item.label ? "#000" : "black",
                  textTransform: "capitalize",
                  paddingTop: "30px",
                  border: "none",
                  borderBottom:
                    activeTab === item.label
                      ? "2px solid #3D9BE9"
                      : "2px solid transparent",
                  paddingLeft: "10px",
                  background: "transparent",
                  padding: "10px 20px",
                  display: "inline-block",
                }}
                disabled={isTabDisabled}
              >
                {item.label}
              </button>
            )}
          </>
        );
      })}
    </div>
  );
};

export default TabStrip;
