import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import isAfter from "date-fns/isAfter";
import startOfMonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import style from "./index.module.scss";
import "./dateRangePicker.scss";
import "rsuite/DateRangePicker/styles/index.css";

const DateRangePickerShortCuts = ({ setDate, className }) => {
  const todays = new Date();
  const defaultToday = new Date();
  const thirtyDaysAgo = moment(todays).subtract(30, "days").startOf("day");

  const predefinedRanges = [
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
    },
    {
      label: "All time",
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: "left",
    },
  ];

  return (
    <div className={style.dateRangeWrapper}>
      {/* <div className={style.reportName}>User Onboarding Report</div> */}
      <div className={style.dateRangeShortCut}>
        <DateRangePicker
          className={`${style.dateRange} ${className}`}
          ranges={predefinedRanges}
          placeholder="Date Range Select"
          style={{ width: 224 }}
          character={"~"}
          editable
          defaultCalendarValue={[addMonths(defaultToday, -1), defaultToday]}
          defaultValue={[addMonths(defaultToday, -1), defaultToday]}
          onShortcutClick={(shortcut) => {
            setDate(() => ({
              start_date: shortcut?.value?.[0].toISOString(),
              end_date: shortcut?.value?.[1].toISOString(),
            }));
          }}
          onChange={(data) => {
            if (data?.length) {
              setDate(() => ({
                start_date: data?.[0].toISOString(),
                end_date: data?.[1].toISOString(),
              }));
            } else {
              setDate(() => ({
                start_date: thirtyDaysAgo.toISOString(),
                end_date: todays.toISOString(),
              }));
            }
          }}
          shouldDisableDate={(date) => isAfter(date, new Date())}
          appearance={"default"}
        />
      </div>
    </div>
  );
};
export default DateRangePickerShortCuts;
