import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import dayjs from "dayjs";

const CustomTimePicker = ({
  onChange,
  placeholder = "Select",
  selectedTime,
  isDisabled = false,
}) => {
  const [decisecond, setDecisecond] = useState(0); // State for deciseconds

  useEffect(() => {
    if (selectedTime) {
      const parts = selectedTime.split(".");
      if (parts.length === 2) {
        setDecisecond(parseInt(parts[1], 10));
      }
    }
  }, [selectedTime]);

  const handleDateChange = (time) => {
    if (onChange) {
      const formattedTime = time.format("HH:mm:ss");
      const formatted = `${formattedTime}.${decisecond}`;
      onChange(formatted);
    }
  };

  const handleDecisecondChange = (event) => {
    setDecisecond(event.target.value);
    if (selectedTime) {
      const formattedTime = dayjs(selectedTime, "HH:mm:ss").format("HH:mm:ss");
      const formatted = `${formattedTime}.${event.target.value}`;
      onChange(formatted);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        views={["hours", "minutes", "seconds"]}
        ampm={false}
        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
        slotProps={{
          textField: { placeholder: `${placeholder}` },
          field: {
            readOnly: true,
          },
        }}
        className="time-picker"
        value={selectedTime ? dayjs(selectedTime.split(".")[0], "HH:mm:ss") : null}
        onAccept={handleDateChange}
        disabled={isDisabled}
      />
      <FormControl fullWidth>
        <InputLabel id="decisecond-label">Deciseconds</InputLabel>
        <Select
          labelId="decisecond-label"
          id="decisecond-select"
          value={decisecond}
          onChange={handleDecisecondChange}
          disabled={isDisabled}
        >
          {Array.from({ length: 10 }, (_, i) => (
            <MenuItem key={i} value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
